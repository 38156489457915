/* eslint-disable react/forbid-prop-types */
import React, { useMemo } from 'react';
import cx from 'classnames';
import Proptypes from 'prop-types';
import {
  Typography, Divider, TextField, Button, Box,
} from '@material-ui/core';
import CardReward from '../../_molecules/CardReward/card-reward';

import ToggleButtons from '../../_molecules/ToggleButtons/toggle-buttons';
import { groupBy } from '../../../utils/array';
import { parseImageByTypeReward } from '../../../utils/image';
import tokenOxya from '../../../assets/rewards/tokenOxya.png';
import tokenLukrya from '../../../assets/rewards/tokenLukrya.png';
import alkar from '../../../assets/rewards/alkar.png';
import electrium from '../../../assets/rewards/electrium.png';
import steelvar from '../../../assets/rewards/steelvar.png';

import withInventory from './inventory.style';

function Inventory({
  classes,
  idRoot,
  className,
  quantityOxya,
  quantityLukrya,
  quantityAlkar,
  quantitySteelvar,
  quantityElectrium,
  titleHeader,
  textHeader,
  listRewards,
  listRewardsToDisplay,
  textNoFound,
  onClickSubmitDiscord,
  onClickToggleButtons,
  onChangeDiscordName,
  valueDiscordName,
  activeTab,
}) {
  const rewardsToDisplay = useMemo(() => groupBy(listRewardsToDisplay?.filter((elem) => elem?.isClaimed
      && (elem?.type?.indexOf('OXYA') === -1 && (elem.description === undefined || (elem?.description?.indexOf('OXYA') === -1) || (elem?.description?.indexOf('OXYA HQ') !== -1)) // Ne pas afficher les $OXYA
      && (elem?.description === undefined || elem.description.indexOf('steelvar') === -1) // Ne pas afficher les resources
      )), 'type'), [listRewardsToDisplay]);
  return (
    <div className={cx(classes.root, className)} id={idRoot}>
      <div className={classes.header}>
        <Typography variant="h1" className={classes.titleHeader} style={{ display: 'flex', alignItems: 'center' }}>{titleHeader}</Typography>
        <Typography className={classes.textHeader}>{textHeader}</Typography>
      </div>
      {/* <Divider className={classes.divider} /> */}
      <div className={classes.body}>
        {
              listRewards?.length === 0 ? (
                <Typography className={classes.textNoFound}>{textNoFound}</Typography>
              ) : (
                <>
                  {
                  listRewards?.length > 0 && (
                    <>
                      <ToggleButtons onClick={onClickToggleButtons} activeTab={activeTab} />
                      <Box className={classes.wrapperCard}>
                        <CardReward
                          name="$OXYA"
                          firstRadius
                          image={tokenOxya}
                          description={quantityOxya}
                          noRewardBox
                        />
                        <CardReward
                          name="$LUKRYA"
                          firstRadius={false}
                          image={tokenLukrya}
                          description={quantityLukrya}
                          noRewardBox
                        />
                      </Box>
                      <Box className={classes.wrapperCard}>
                        <CardReward
                          name="Alkar"
                          firstRadius
                          image={alkar}
                          description={quantityAlkar}
                          noRewardBox
                          className={classes.rewardResource}
                        />
                        <CardReward
                          name="Steelvar"
                          firstRadius={false}
                          image={steelvar}
                          description={quantitySteelvar}
                          noRewardBox
                          className={classes.rewardResource}
                        />
                        <CardReward
                          name="Electrium"
                          firstRadius
                          image={electrium}
                          description={quantityElectrium}
                          noRewardBox
                          className={classes.rewardResource}
                        />
                      </Box>
                      <div className={classes.wrapperCard}>
                        {
                    rewardsToDisplay.map((elem, index) => (
                      <React.Fragment key={elem?.groupList[0].id}>
                        <CardReward
                          numberReward={elem?.groupList.length}
                          name={elem?.groupList[0].type}
                          image={parseImageByTypeReward(elem?.groupList[0].type)}
                          firstRadius={index % 2 === 0}
                          description={elem?.groupList[0]?.description}
                        />
                      </React.Fragment>
                    ))
                  }
                      </div>
                    </>
                  )
                }
                </>
              )
          }
      </div>
      <Divider className={classes.divider} />
      <div className={classes.footer}>
        <Typography className={classes.textDiscord}>Provide your discord name for prize distribution</Typography>
        <TextField
          label="Your discord name"
          value={valueDiscordName}
          onChange={onChangeDiscordName}
          variant="outlined"
          className={classes.textField}
        />
        <Button
          className={classes.btnSubmitDiscord}
          onClick={onClickSubmitDiscord}
          classes={{ label: classes.textBtnDiscord }}
        >
          Submit
        </Button>
      </div>
    </div>
  );
}

Inventory.propTypes = {
  classes: Proptypes.objectOf(
    Proptypes.string,
  ).isRequired,
  className: Proptypes.string,
  titleHeader: Proptypes.string,
  textHeader: Proptypes.string,
  listRewards: Proptypes.array,
  listRewardsToDisplay: Proptypes.array,
  textNoFound: Proptypes.string,
  idRoot: Proptypes.string,
  onClickToggleButtons: Proptypes.func,
  onChangeDiscordName: Proptypes.func,
  onClickSubmitDiscord: Proptypes.func,
  valueDiscordName: Proptypes.string,
  activeTab: Proptypes.number,
  quantityOxya: Proptypes.number,
  quantityAlkar: Proptypes.number,
  quantitySteelvar: Proptypes.number,
  quantityElectrium: Proptypes.number,
  quantityLukrya: Proptypes.number,
};

Inventory.defaultProps = {
  idRoot: '',
  className: '',
  titleHeader: 'Inventory',
  textHeader: 'These items can be claimed on the OXYA HQ',
  textNoFound: "Stake your Oxyans to enter next week's shuffle",
  listRewards: [],
  listRewardsToDisplay: [],
  onClickToggleButtons: () => {},
  onChangeDiscordName: () => {},
  onClickSubmitDiscord: () => {},
  valueDiscordName: '',
  activeTab: 1,
  quantityOxya: 0,
  quantityLukrya: 0,
  quantityAlkar: 0,
  quantitySteelvar: 0,
  quantityElectrium: 0,
};

export default withInventory(Inventory);
