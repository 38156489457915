// eslint-disable-next-line import/no-cycle
import { contractOperatorLand, contractLandNft } from './contract';

export const stake = async (eth, account, tokensId, cbSuccess = () => {}, cbError = () => {}) => {
  const operatorLandContract = new eth.Contract(
    contractOperatorLand.abi,
    contractOperatorLand.address,
  );

  const landNftContract = new eth.Contract(
    contractLandNft.abi,
    contractLandNft.address,
  );

  const addressOperator = process.env.REACT_APP_OPERATOR_LANDS_CONTRACT_ADDRESS;

  const isApprovedForAll = await landNftContract.methods.isApprovedForAll(account, addressOperator).call();
  if (isApprovedForAll === false) {
    landNftContract.methods.setApprovalForAll(addressOperator, true).send({ from: account })
      .then(async () => {
        operatorLandContract.methods.batchStakeX1NFT(tokensId).send({ from: account })
          .then((elem) => {
            cbSuccess(elem);
          })
          .catch((error) => {
            cbError(error);
          });
      })
      .catch((error) => { cbError(error); });
  } else {
    operatorLandContract.methods.batchStakeX1NFT(tokensId).send({ from: account })
      .then((elem) => {
        cbSuccess(elem);
      })
      .catch((error) => {
        cbError(error);
      });
  }
};

export const unstake = async (eth, account, tokensId, cbSuccess = () => {}, cbError = () => {}) => {
  const operatorLandContract = new eth.Contract(
    contractOperatorLand.abi,
    contractOperatorLand.address,
  );

  const landNftContract = new eth.Contract(
    contractLandNft.abi,
    contractLandNft.address,
  );
  const addressOperator = process.env.REACT_APP_OPERATOR_LANDS_CONTRACT_ADDRESS;

  const isApprovedForAll = await landNftContract.methods.isApprovedForAll(account, addressOperator).call();

  if (isApprovedForAll === false) {
    landNftContract.methods.setApprovalForAll(addressOperator, true).send({ from: account })
      .then(async () => {
        operatorLandContract.methods.batchUnstakeX1NFT(tokensId).send({ from: account })
          .then((elem) => {
            cbSuccess(elem);
          })
          .catch((error) => {
            cbError(error);
          });
      })
      .catch((error) => { cbError(error); });
  } else {
    operatorLandContract.methods.batchUnstakeX1NFT(tokensId).send({ from: account })
      .then((elem) => {
        cbSuccess(elem);
      })
      .catch((error) => {
        cbError(error);
      });
  }
};
