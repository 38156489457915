/* eslint-disable no-underscore-dangle */
import imageOxya from '../constants/imageOxyaNFT.json';
/* eslint-disable no-await-in-loop */
// eslint-disable-next-line import/no-cycle
import { contractStakingOxyan, contractOxyaNft } from './contract';

export const numberOfStakeOxyans = async (eth, cbSuccess = () => {}) => {
  const oxyaNftContract = new eth.Contract(
    contractOxyaNft.abi,
    contractOxyaNft.address,
  );
  const result = await oxyaNftContract.methods
    .balanceOf(contractStakingOxyan.address)
    .call();

  cbSuccess(result);
  return result;
};

export const getRealStakeOxyan = async (
  eth,
  needStakingContract = true,
  stakingContractParam = null,
  cbSuccess = () => {},
) => {
  let stakingContract = stakingContractParam;
  if (needStakingContract) {
    stakingContract = new eth.Contract(
      contractStakingOxyan.abi,
      contractStakingOxyan.address,
    );
  } // 14724180
  const staked = await stakingContract.getPastEvents('Staked', {
    fromBlock: process.env.REACT_APP_BEGIN_BLOCK_AVATARS,
    toBlock: 'latest',
  });
  const unStaked = await stakingContract.getPastEvents('Unstaked', {
    fromBlock: process.env.REACT_APP_BEGIN_BLOCK_AVATARS,
    toBlock: 'latest',
  });
  const realStake = staked.filter(
    (event) => !unStaked.find(
      (unEvent) => unEvent.returnValues.tokenId === event.returnValues.tokenId
          && unEvent.returnValues.timeframe >= event.returnValues.timeframe,
    ),
  );

  cbSuccess(realStake);
  return realStake;
};

export const getOxyaNftStakedOwner = async (eth, account) => {
  const stakingContract = new eth.Contract(
    contractStakingOxyan.abi,
    contractStakingOxyan.address,
  );

  const realStake = await getRealStakeOxyan(eth, false, stakingContract);
  realStake.sort(
    (a, b) => a.returnValues.blockNumber - b.returnValues.blockNumber,
  );
  const realStakeOwner = realStake.filter(
    (event) => event.returnValues?.owner?.toLowerCase() === account?.toLowerCase(),
  );

  return realStakeOwner;
};

export const getNumberOxyaNftNoStakedOwner = async (eth, account) => {
  const oxyaNftContract = new eth.Contract(
    contractOxyaNft.abi,
    contractOxyaNft.address,
  );
  const nbTokensNoStake = await oxyaNftContract.methods
    .balanceOf(account)
    .call();

  return nbTokensNoStake;
};

export const getOxyaNft = async (eth, account) => {
  const oxyaNftContract = new eth.Contract(
    contractOxyaNft.abi,
    contractOxyaNft.address,
  );
  const nbTokensNoStake = await getNumberOxyaNftNoStakedOwner(eth, account);

  const realStakeOwner = await getOxyaNftStakedOwner(eth, account);

  const tokens = realStakeOwner.map((event) => ({
    tokenId: event.returnValues.tokenId,
    image: '',
    isStake: true,
    tokenOwner: event.returnValues.owner,
    timestamp: event.returnValues.timeframe,
  }));

  for (let i = 0; i < nbTokensNoStake; i += 1) {
    const tokenId = await oxyaNftContract.methods
      .tokenOfOwnerByIndex(account, i)
      .call();
    tokens.push({
      tokenId,
      image: '',
      isStake: false,
      timestamp: null,
      tokenOwner: null,
    });
  }

  for (let i = 0; i < tokens.length; i += 1) {
    tokens[i].image = imageOxya.filter(
      (elem) => elem.ID === parseInt(tokens[i].tokenId, 10),
    )[0].IMG_URL;
  }
  return tokens.reverse();
};
