import { withStyles } from '@material-ui/core';

export default withStyles(() => ({
  footer: {
    margin: '4rem auto',
    width: '90%',
    height: '14rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  title: {
    fontSize: '1.2rem',
    textTransform: 'uppercase',
    color: 'white',
  },
  wrapperContent: {
    display: 'flex',
    fontSize: '0.75rem',
    lineHeight: '1.2rem',
    color: 'white',
    gap: '1.25rem',
    justifyContent: 'space-evenly',
    '& div': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      fontSize: '1rem',
      '& strong': {
        fontSize: '1.4rem',
        marginBottom: '0.5rem',
      },
      '& a': {
        color: 'white',
        textDecoration: 'none',
        transition: '0.2s opacity ease-in-out',
        '&:hover': {
          opacity: 0.8,
        },
      },
    },
  },
}));
