export const parseAccount = (account) => {
  const begin = account.substr(0, 5);
  const end = account.substr(account.length - 5, account.length);
  return `${begin}.....${end}`;
};

export const titleFede = (charasStaked, landsStaked, coloniesStaked) => {
  const nbLandsStaked = landsStaked + coloniesStaked * 4; // One colony staked is 4 lands staked
  if (charasStaked >= 24 && nbLandsStaked >= 16) { return 'Chancellor'; }
  if (charasStaked >= 12 && nbLandsStaked >= 8) { return 'Councillor'; }
  if (charasStaked >= 6 && nbLandsStaked >= 4) return 'Governor';
  if (charasStaked >= 3 && nbLandsStaked >= 2) return 'Ambassador';
  if (
    (charasStaked >= 1 && nbLandsStaked >= 1)
    || charasStaked >= 2
  ) { return 'Landlord'; }
  return 'Unranked';
};
