/* eslint-disable jsx-a11y/media-has-caption */
import React from 'react';
import cx from 'classnames';
import Proptypes from 'prop-types';

import { Typography } from '@material-ui/core';
import withCardReward from './card-reward.style';

function CardReward({
  classes, className, numberReward, image, name, description, noRewardBox,
}) {
  return (
    <div className={cx(classes.root, className)}>
      {
        noRewardBox === false && (
          <>
            <Typography className={classes.text} style={{ alignSelf: 'flex-end' }}>
              x
              <span className={classes.textBigger}>{numberReward}</span>
            </Typography>
          </>
        )
      }
      <img src={image} alt={`reward ${name}`} className={classes.image} />
      <Typography className={cx(classes.text, classes.textBigger)} style={{ fontSize: '1.6rem' }}>{name}</Typography>
      <Typography className={classes.text} style={{ textAlign: 'center', fontSize: '1.2rem' }}>{description}</Typography>
    </div>
  );
}

CardReward.propTypes = {
  classes: Proptypes.objectOf(
    Proptypes.string,
  ).isRequired,
  className: Proptypes.string,
  numberReward: Proptypes.oneOfType([
    Proptypes.string, Proptypes.number,
  ]),
  image: Proptypes.node,
  name: Proptypes.string,
  description: Proptypes.string,
  noRewardBox: Proptypes.bool,
};

CardReward.defaultProps = {
  className: '',
  numberReward: 1,
  image: null,
  name: '',
  description: '',
  noRewardBox: false,
};

export default withCardReward(CardReward);
