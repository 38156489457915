import React from 'react';
import Proptypes from 'prop-types';
import cx from 'classnames';
import { HashLink } from 'react-router-hash-link';

import {
  AppBar, Divider, Drawer, IconButton, List, ListItem, ListItemText, Toolbar, Typography,
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import logo from '../../../assets/logo.png';

import withHeader from './header.style';
import { listDrawerHeader } from '../../../constants/header';
import ConnectWallet from '../../_atoms/ConnectWallet/connectWallet';

function Header({
  classes, currentAccount, location, accessToken, displayConnect,
}) {
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <AppBar className={classes.root} position="sticky">
      <Toolbar className={classes.toolbar}>
        <div className={classes.sectionDesktop}>
          <div className={classes.logoNav}>
            <HashLink smooth to="/" className={classes.linkNav}>
              <img src={logo} alt="logo" className={classes.logo} />
            </HashLink>
            <nav role="navigation" className={cx(classes.nav, (currentAccount === null || accessToken === null) && classes.noDisplay)}>
              <HashLink smooth to="/" className={cx(classes.linkNav, location.pathname === '/' && classes.linkNavActive)}>Rewards</HashLink>
              <HashLink smooth to="/oxyans" className={cx(classes.linkNav, location.pathname === '/oxyans' && classes.linkNavActive)}>Oxyans</HashLink>
              <HashLink smooth to="/plots" className={cx(classes.linkNav, location.pathname === '/plots' && classes.linkNavActive)}>Plots</HashLink>
              <HashLink smooth to="/colonies" className={cx(classes.linkNav, location.pathname === '/colonies' && classes.linkNavActive)}>Colonies</HashLink>
            </nav>
          </div>
          {
            displayConnect && (
            <div className={classes.socialConnect}>
              <ConnectWallet />
            </div>
            )
          }
        </div>
        <div className={cx(classes.mobileSection, currentAccount === null && classes.noDisplay)}>
          <div className={classes.containerMobile}>
            <img src={logo} alt="logo" className={classes.logo} />
            <IconButton
              edge="start"
              className={classes.menuButton}
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
            >
              <MenuIcon fontSize="large" classes={{ fontSizeLarge: classes.iconLarge }} />
            </IconButton>
          </div>
          <Drawer
            className={classes.drawer}
            anchor="right"
            open={open}
            classes={{
              paper: classes.drawerPaper,
            }}
            onClose={handleDrawerClose}
          >
            <div className={classes.drawerHeader}>
              <IconButton className={classes.closeBtn} onClick={handleDrawerClose}>
                <CloseIcon classes={{ fontSizeLarge: classes.iconLarge }} fontSize="large" />
              </IconButton>
            </div>
            <Divider className={classes.divider} />
            <List>
              {
                listDrawerHeader.map((element) => (
                  <HashLink smooth to={element.to} key={`link${element.key}`} className={classes.linkNav}>
                    <ListItem className={classes.listItem} button onClick={handleDrawerClose}>
                      <ListItemText
                        primary={element.text}
                        classes={{ primary: classes.listText }}
                      />
                    </ListItem>
                  </HashLink>
                ))
              }
              {
                displayConnect && (
                  <>
                    <Divider className={classes.divider} />
                    <div className={classes.containerInfo}>
                      {
                  currentAccount === null && (
                    <Typography variant="body1" className={classes.textInfo}>Unlock your Oxyans by connecting your wallet</Typography>
                  )
                }
                      <ConnectWallet />
                    </div>
                  </>
                )
              }
            </List>
          </Drawer>
        </div>
      </Toolbar>
    </AppBar>
  );
}

Header.propTypes = {
  classes: Proptypes.objectOf(
    Proptypes.string,
  ).isRequired,
  accessToken: Proptypes.string,
  currentAccount: Proptypes.string,
  location: Proptypes.shape({
    hash: Proptypes.string,
    key: Proptypes.string,
    pathname: Proptypes.string,
    search: Proptypes.string,
    // eslint-disable-next-line react/forbid-prop-types
    state: Proptypes.object,
  }),
  displayConnect: Proptypes.bool,
};

Header.defaultProps = {
  currentAccount: null,
  location: {},
  accessToken: null,
  displayConnect: true,
};

export default withHeader(Header);
