import { withStyles } from '@material-ui/core';

export default withStyles(() => ({
  btn: {
    color: 'white',
    border: 'none',
    width: '14rem',
  },
  textBtn: {
    fontFamily: 'Montserrat, sans-serif',
    fontSize: '1.4rem',
  },
  btnActive: {
    color: '#E5BA57',
    borderBottom: '1px solid #E5BA57',
  },
}));
