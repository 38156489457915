import React, { useEffect, useState } from 'react';
import {
  useAccount,
} from 'wagmi';

import { disconnect, signMessage } from '@wagmi/core';

import Proptypes from 'prop-types';

import MaterialTable from 'material-table';

import { Typography } from '@material-ui/core';
import SnackBarBottom from '../../_atoms/SnackBarBottom/snack-bar-bottom';
import BeAdmin from '../../../containers/BeAdmin/be-admin';

import withAdminGatemap from './admin-gatemap.style';
import { loadState, parseBearerToken, removeState } from '../../../store/localStorage';
import { requestMessage, signIn } from '../../../api/auth';
import {
  createLootGatemapAdmin, getLootsGatemapAdmin, getRewardsGatemapAdmin, updateLootGatemapAdmin,
  deleteLootGatemapAdmin,
} from '../../../api/admin';
import { COLUMN_LOOT_GATEMAP, COLUMN_REWARDS } from '../../../constants/columnTable';
import ConnectWallet from '../../_atoms/ConnectWallet/connectWallet';

function AdminGatemap({ classes }) {
  const [rewards, setRewards] = React.useState(undefined);
  const [loots, setLoots] = React.useState(undefined);
  const [currentAccount, setCurrentAccount] = useState(null);
  const [accessToken, setAccessToken] = useState(undefined);
  const [roles, setRoles] = useState([]);
  const [openSnackError, setOpenSnackError] = React.useState(false);
  const [openSnackSuccess, setOpenSnackSuccess] = React.useState(false);
  const [errorText, setErrorText] = React.useState('');
  const [successText, setSuccessText] = React.useState('');

  const logout = () => {
    setCurrentAccount(null);
    setAccessToken(null);
    removeState('accessToken');
    removeState('roles');
    removeState('address');
    disconnect();
  };

  const handleCloseSnack = () => {
    setSuccessText('test');
    setOpenSnackError(false);
    setOpenSnackSuccess(false);
  };

  const handleAuth = async () => {
    const message = await requestMessage(address);
    try {
      const signature = await signMessage({
        message,
      });
      await signIn(address, signature, message, (elem) => {
        setAccessToken(parseBearerToken(elem.accessToken));
        setRoles(elem.roles);
        getRewardsGatemapAdmin((rewardRes) => {
          setRewards(rewardRes.map((rewardMap) => ({
            ...rewardMap,
            discordId: rewardMap.user ? rewardMap.user?.discordId : '',
          })));
        });
        getLootsGatemapAdmin((lootRes) => {
          setLoots(lootRes);
        });
      });
    } catch (e) {
      logout();
    }
  };

  /* const checkWeb3 = useCallback(async () => {
    removeState('accessToken');
    removeState('roles');
    const { ethereum } = window;

    if (!ethereum) {
      setErrorText('Make sure you have Metamask installed !');
      setOpenSnackError(true);
    } else {
      const web3 = new Web3(ethereum);
      web3.eth.setProvider(ethereum);

      const chainIdWeb3 = await web3.eth.getChainId();

      setChainId(chainIdWeb3);

      // console.log(chainIdWeb3);

      if (chainIdWeb3 !== undefined && chainIdWeb3 !== null && chainIdWeb3 !== parseInt(process.env.REACT_APP_CHAIN, 10)) {
        setOpenSnackError(true);
        setErrorText(`You have to change your network to ${chainIDToName[parseInt(process.env.REACT_APP_CHAIN, 10)]} !`);
      }
      ethereum.on('accountsChanged', async () => {
        window.location.reload(true);
      });

      ethereum.on('chainChanged', () => {
        // $.cookie("MetaMask", "");
        window.location.reload(true);
      });
    }
  }, []); */

  const { address } = useAccount({
    onDisconnect() {
      logout();
    },
  });

  useEffect(() => {
    removeState('accessToken');
    removeState('roles');
    const saveAddress = loadState('address', false);
    const accessTokenLocal = loadState('accessToken');
    setCurrentAccount(address);
    if (address !== saveAddress || accessTokenLocal === null || accessTokenLocal === undefined) {
      setAccessToken(null);
      handleAuth(address);
    }
  }, [address]);

  /* const handleClickConnect = async () => {
    const { ethereum } = window;

    if (!ethereum) {
      setOpenSnackError(true);
      setErrorText('Please install Metamask!');
    } else if (chainId !== parseInt(process.env.REACT_APP_CHAIN, 10)) {
      setOpenSnackError(true);
      setErrorText(`You have to change your network to ${chainIDToName[parseInt(process.env.REACT_APP_CHAIN, 10)]} !`);
    } else {
      try {
        if (currentAccount === null || accessToken === null || accessToken === undefined) {
          ethereum.request({ method: 'eth_requestAccounts' })
            .then((accounts) => {
              if (accounts.length !== 0) {
                setCurrentAccount(accounts[0]);
                setOpenSnackError(false);
                setErrorText('');

                const web3 = new Web3(ethereum);
                sign(
                  accounts[0],
                  web3.eth,
                  (elem) => {
                    setAccessToken(parseBearerToken(elem.accessToken));
                    setRoles(elem.roles);
                    getRewardsAdmin((rewardRes) => {
                      setRewards(rewardRes.map((rewardMap) => ({
                        ...rewardMap,
                        discordId: rewardMap.user ? rewardMap.user?.discordId : '',
                      })));
                    });
                    getLootsAdmin((lootRes) => {
                      setLoots(lootRes);
                    });
                  },
                  (err) => {
                    console.log(err, 'sign');
                    logout();
                  },
                );
              } else {
                setOpenSnackError(true);
                setErrorText('An error occured !');
                logout();
              }
            });
        } else {
          logout();
        }
      } catch (e) {
        console.log(e);
        logout();
      }
    }
  }; */

  if (accessToken === undefined || accessToken === null) {
    return (
      <div className={classes.bg}>
        <div className={classes.wrapperNoConnected}>
          <Typography variant="h2" className={classes.title}>Administration Gatemap</Typography>
          <ConnectWallet />
        </div>
      </div>
    );
  }
  return (
    <BeAdmin
      account={currentAccount}
      accessToken={accessToken}
      roles={roles}
    >
      {
        openSnackError && (
          <SnackBarBottom variant="error" open={openSnackError} onClose={handleCloseSnack} text={errorText} />
        )
      }
      {
        openSnackSuccess && (
          <SnackBarBottom variant="success" open={openSnackSuccess} onClose={handleCloseSnack} text={successText} />
        )
      }
      <div className={classes.root}>
        {
          rewards !== undefined && (
            <div className={classes.container}>
              <MaterialTable
                title="AllRewards Gatemap"
                data={rewards}
                columns={COLUMN_REWARDS}
                options={{
                  filtering: true,
                  columnsButton: true,
                  exportButton: true,
                }}
              />
            </div>
          )
        }
        {
          loots !== undefined && (
            <div className={classes.container}>
              <MaterialTable
                title="Loots Gatemap"
                data={loots}
                columns={COLUMN_LOOT_GATEMAP}
                options={{
                  filtering: true,
                  columnsButton: true,
                }}
                editable={{
                  onRowAdd: (loot) => new Promise((resolve) => {
                    if (loot.type && loot.sizeLand && (loot.tokenIdLand !== null || loot.tokenIdLand !== undefined)) {
                      createLootGatemapAdmin(
                        loot,
                        () => {
                          setOpenSnackSuccess(true);
                          setSuccessText('New loot Gatemap was created !');
                          getLootsGatemapAdmin((lootRes) => {
                            setLoots(lootRes);
                          });
                        },
                        () => {
                          setOpenSnackError(true);
                          setErrorText('An error occured !');
                        },
                      );
                    } else {
                      setOpenSnackError(true);
                      setErrorText('All informations must be enter !');
                    }
                    resolve();
                  }),
                  onRowUpdate: (newData) => new Promise((resolve) => {
                    updateLootGatemapAdmin(newData, () => {
                      setOpenSnackSuccess(true);
                      setSuccessText('Loot Gatemap was updated !');
                      getLootsGatemapAdmin((lootRes) => {
                        setLoots(lootRes);
                      });
                    }, () => {
                      setOpenSnackError(true);
                      setErrorText('An error occured !');
                    });
                    resolve();
                  }),
                  onRowDelete: (oldData) => new Promise((resolve) => {
                    deleteLootGatemapAdmin(oldData.id, () => {
                      setOpenSnackSuccess(true);
                      setSuccessText('Loot Gatemap was deleted !');
                      getLootsGatemapAdmin((lootRes) => {
                        setLoots(lootRes);
                      });
                    }, () => {
                      setOpenSnackError(true);
                      setErrorText('An error occured !');
                    });
                    resolve();
                  }),
                }}
              />
            </div>
          )
        }
      </div>
    </BeAdmin>
  );
}

AdminGatemap.propTypes = {
  classes: Proptypes.objectOf(
    Proptypes.string,
  ).isRequired,
};

export default withAdminGatemap(AdminGatemap);
