import React from 'react';
import cx from 'classnames';
import Proptypes from 'prop-types';

import {
  Backdrop, Fade, IconButton, Modal, Typography,
} from '@material-ui/core';

import CloseIcon from '@material-ui/icons/Close';

import ButtonPerso from '../ButtonPerso/button-perso';

import withModalCenter from './modal-center.style';

function ModalCenter({
  classes,
  className,
  classNamePaper,
  classNameBody,
  open,
  onClose,
  titleTop,
  textTop,
  children,
  textCancelBtn,
  textConfirm,
  textConfirm2,
  onClickConfirm,
  onClickConfirm2,
  button,
  needTop,
  needCloseBtn,
  secondBtnConfirm,
}) {
  return (
    <Modal
      className={cx(classes.modal, className)}
      open={open}
      onClose={onClose}
      closeAfterTransition
      BackdropProps={{
        timeout: 500,
      }}
      BackdropComponent={Backdrop}
    >
      <Fade in={open}>
        <div className={cx(classes.paper, classNamePaper)}>
          {
            needCloseBtn && (
              <IconButton aria-label="close" className={cx(classes.closeButton)} onClick={onClose}>
                <CloseIcon />
              </IconButton>
            )
          }
          {
            needTop && (
              <div className={classes.top}>
                <Typography variant="h3" className={classes.titleTop}>{titleTop}</Typography>
                <Typography variant="body1" className={classes.textTop}>{textTop}</Typography>
              </div>
            )
          }
          <div className={cx(classes.body, classNameBody)}>
            {children}
          </div>
          {
              button && (
                <div className={classes.footer}>
                  <ButtonPerso classNameText={classes.btnText} className={classes.btnCancel} text={textCancelBtn} onClick={onClose} />
                  <ButtonPerso classNameText={classes.btnText} className={classes.btnConfirm} text={textConfirm} onClick={onClickConfirm} firstRadius={false} />
                  {
                    secondBtnConfirm && (
                      <ButtonPerso classNameText={classes.btnText} className={classes.btnConfirm} text={textConfirm2} onClick={onClickConfirm2} />
                    )
                  }
                </div>
              )
          }
        </div>
      </Fade>
    </Modal>
  );
}

ModalCenter.propTypes = {
  classes: Proptypes.objectOf(
    Proptypes.string,
  ).isRequired,
  classNamePaper: Proptypes.oneOfType([Proptypes.string, Proptypes.object]),
  open: Proptypes.bool,
  button: Proptypes.bool,
  needCloseBtn: Proptypes.bool,
  needTop: Proptypes.bool,
  onClose: Proptypes.func,
  onClickConfirm: Proptypes.func,
  titleTop: Proptypes.string,
  textTop: Proptypes.string,
  textCancelBtn: Proptypes.string,
  textConfirm: Proptypes.string,
  classNameBody: Proptypes.string,
  className: Proptypes.string,
  children: Proptypes.node,
  secondBtnConfirm: Proptypes.bool,
  onClickConfirm2: Proptypes.func,
  textConfirm2: Proptypes.string,
};

ModalCenter.defaultProps = {
  classNamePaper: '',
  className: '',
  open: false,
  button: false,
  needCloseBtn: true,
  classNameBody: '',
  onClose: () => {},
  onClickConfirm: () => {},
  titleTop: "You're about to make some changes...",
  textTop: 'Do you really want to make them ?',
  textCancelBtn: 'Cancel',
  textConfirm: 'Confirm',
  textConfirm2: 'Merge & Stake',
  children: null,
  needTop: true,
  secondBtnConfirm: false,
  onClickConfirm2: () => {},
};

export default withModalCenter(ModalCenter);
