import { withStyles } from '@material-ui/core';
import bg from '../../assets/stars.jpg';

export default withStyles(() => ({
  app: {
    minWidth: '100vw',
    minHeight: '100vh',
    overflow: 'scroll',
    background: '#030814',
    backgroundPosition: 'center center',
    backgroundAttachment: 'fixed',
    textAlign: 'center',
    fontFamily: 'Montserrat',
    position: 'relative',
    zIndex: 1,
  },
  addBg: {
    backgroundImage: `url(${bg})`,
  },
  socialMedia: {
    position: 'fixed',
    left: 'auto',
    top: 'auto',
    right: '2rem',
    bottom: '2rem',
    zIndex: 9999,
  },
}));
