import { withStyles } from '@material-ui/core';

export default withStyles(() => ({
  container: {
    fontFamily: 'Montserrat',
    textAlign: 'justify',
    '& h1': {
      textAlign: 'center',
      color: 'white',
      fontSize: '3rem',
      textTransformation: 'uppercase',
    },
  },
  content: {
    fontFamily: 'Montserrat',
    color: 'white',
    width: '90%',
    margin: 'auto',
    '& h2': {
      fontSize: '1.4rem',
    },
    '& p': {
      fontSize: '1.2rem',
    },
    '& a': {
      color: 'white',
    },
  },
}));
