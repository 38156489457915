export const textCardNoFound = [
  {
    key: 1,
    textCardNoFound: "It's never too late to play",
    textBtn: 'Buy Oxyans on opensea',
    oxyaRooter: false,
    to: 'https://opensea.io/collection/oxyaoriginproject',
  },
];

export const textCardNoFoundLands = [
  {
    key: 1,
    textCardNoFound: "It's never too late to play",
    textBtn: 'Buy Lands on opensea',
    oxyaRooter: false,
    to: 'https://opensea.io/collection/gatesofoxya-lands',
  },
];

export const textCardNoFoundColonies = [
  {
    key: 1,
    textCardNoFound: "It's never too late to play",
    textBtn: 'Buy Colonies on opensea',
    oxyaRooter: false,
    to: 'https://opensea.io/collection/gatesofoxyacolony',
  },
];

export const textInfoRewardsChara = {
  title: 'All details about staking here',
  description: 'Earn a Mystery Box per Oxyan for every staking week. \n'
  + 'The drop goes down every Sunday at 9.a.m CET. \n'
  + 'Claim your rewards before the next drop to avoid seeing them expire. \n'
  + 'Mystery Box drop rates depend on the rarity & nobility clusters which will be on a case by case basis. \n'
  + 'More on twitter and discord. \n',
  details: 'Mystery Box rewards are as follows: \n'
  + '1 - ETH in 0.3 drops. \n'
  + '2 - 1x1 Plots of Land. \n'
  + '3 - Oxyan Character NFTs. \n'
  + '4 - Weapon NFTs. \n'
  + '5 - $OXYA token. \n'
  + '6 - Resource Pack 1. \n'
  + '7 - Resource Pack 2. \n'
  + '8 - Resource Pack 3. \n'
  + '9 - Whitelists.',
};

export const textInfoRewardsLands = {
  title: 'All details about staking here',
  description: 'Land with ✅ are already staked.\n'
  + 'If you want to Merge your Lands, you need to unstake them first.\n'
  + 'If you have any questions, ask them on Discord or Twitter.\n'
  + 'Stake your Lands and get:'
  + '- Titles of power\n'
  + '- Governance access\n'
  + '- Token airdrops\n'
  + '- 30% royalties\n'
  + '- Surprise airdrop (depending on number of Lands)',
};

export const textInfoRewardsColonies = {
  title: 'All details about staking here',
  description: 'Colonies are the heart of the Oxyan ecosytem.\n'
  + 'Colony with ✅ are already staked.\n'
  + 'Become a true Landlord and benefit from:\n'
  + '- Exclusive Colony assets crafting (unique Weapons, armors, robots)\n'
  + '- Farming yield boosting (faster and more efficient)\n'
  + '- Higher power in the governance \n'
  + '- Special quests access in the Roguelite game (higher rewards, unique game modes, ...)\n'
  + '- Higher Token yield\n'
  + '- Increased visibility on our 3D Map (4x larger plots)\n'
  + 'Colonies are composed of 2 Lands that were merged together. A Colony cannot be splitted back to 2 Lands.\n'
  + 'Only colony will have access to Guild (4x4) merging.',
};

export const textInfoRewardsMerge = {
  title: 'All details about merging here',
  description: 'Merge your Lands today.\n'
  + 'Larger plots provide multiple short-term advantages including more political power, 4x more visibility on the 3D Map, more advantageous token airdrops.\n'
  + 'In the medium term, merged lands will boost resource production on the Oxya HQ and provide a better gaming experience with enough space to build large infrastructures and higher odds of crafting rare assets.',
};
