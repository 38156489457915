import { createTheme } from '@material-ui/core';

const theme = createTheme({
  typography: {
    /* Suppress error "You are using the typography
     * variant caption which will be restyled in the next major release
    */
    useNextVariants: true,
    fontFamily: [
      'Montserrat',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
  },
  palette: {
    primary: {
      light: '#4579a0',
      main: '#1a4062',
      dark: '#10283d',
      contrastText: '#fff',
    },
    secondary: {
      light: '#ffdd99',
      main: '#FFCD62',
      dark: '#FFCD6294',
      contrastText: '#fff',
    },
    grey: {
      800: '#555555',
    },
    gradient: {
      main: 'linear-gradient(90deg, #FFCD62 0%, #FFCD62 0%, #736629 100%)',
      btn: 'linear-gradient(90deg, #FFCD6294 0%, #FFCD6294 0%, #73662994 100%)',
      btnBlue: 'linear-gradient(90deg, #1a4062 0%, #4579a0 100%)',
    },
    background: {
      default: '#fefefe',
    },
  },
  // sm down === <= sm
  // sm up === >= sm
  breakpoints: {
    values: {
      xs: 320, // Smartphone
      sm: 880, // Ipad
      md: 1024, // Small PC
      lg: 1280, // Medium PC
      xl: 1920, // Large PC
    },
  },
  overrides: {
    MuiTypography: {
      h1: {
        fontWeight: 800,
      },
      h2: {
        fontWeight: 700,
        '@media (max-width:800px)': {
          fontSize: '3rem',
        },
      },
      h3: {
        fontWeight: 500,
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
      },
    },
    // Style sheet name ⚛️
    MuiButton: {
      // Name of the rule
      text: {
        // Some CSS
        background: 'none',
        borderRadius: '2rem',
        color: 'white',
      },
      outlined: {
        borderRadius: '2rem',
        color: 'white',
        border: 'solid 2px #fcfcfc',
      },
      label: {
        fontFamily: 'Futura,Roboto,Helvetica, arial, sans-serif',
        fontWeight: 'bold',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        textAlign: 'center',
        textTransform: 'none',
        fontSize: '1.6rem',
      },
      startIcon: {
        display: 'inline',
        position: 'relative',
        top: '4px',
      },
      iconSizeMedium: {
        label: {
          fontSize: 'initial',
        },
      },
    },
    MuiBadge: {
      badge: {
        fontSize: '1rem',
      },
    },
    MuiDivider: {
      root: {
        borderRadius: '6px',
        backgroundImage: 'linear-gradient(to bottom, #fc7756, #fc5564)',
        height: '5px',
      },
    },
    MuiOutlinedInput: {
      notchedOutline: {
        border: 'solid 2px #c9cdd5',
        borderRadius: '1rem',
        fontFamily: 'Futura,Roboto,Helvetica arial, sans-serif',
        fontWeight: '500',
        fontSize: '1.4rem',
        height: '5rem',
        color: '#bbb7b7',
      },
      input: {
        fontSize: '1.4rem',
        transform: 'translate(3px, -4px) scale(1)',
      },
    },
    MuiFormLabel: {
      root: {
        fontSize: '1.4rem',
        fontWeight: '500',
        color: '#bbb7b7',
      },
    },
    MuiTextField: {
      root: {
        '& input': {
          fontSize: '1.4rem',
        },
      },
    },
    MuiInputLabel: {
      outlined: {
        transform: 'translate(19px, 16px) scale(1)',
      },
    },
  },
});

export default theme;
