import { withStyles } from '@material-ui/core';
import { heightHeader } from '../../../constants/size';

export default withStyles(({ breakpoints }) => ({
  root: {
    height: heightHeader,
    background: 'transparent',
    boxShadow: 'none',
  },
  noDisplay: {
    display: 'none',
  },
  toolbar: {
    padding: 0,
    width: '95%',
    display: 'flex',
    justifyContent: 'space-between',
    margin: 'auto',
  },
  sectionDesktop: {
    display: 'none',
    [breakpoints.up(850)]: {
      display: 'flex',
    },
    width: '95%',
    justifyContent: 'space-between',
    margin: 'auto',
  },
  mobileSection: {
    display: 'block',
    width: '95%',
    margin: 'auto',
    [breakpoints.up(850)]: {
      display: 'none',
    },
  },
  containerMobile: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  drawerHeader: {
    margin: 0,
    display: 'flex',
    justifyContent: 'end',
  },
  drawerPaper: {
    background: 'rgba(0,0,0,0.8)',
    width: '100vw',
    minHeight: '50rem',
    boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12);',
    flexShrink: 0,
    zIndex: 999,
  },
  iconLarge: {
    fontSize: '3rem',
  },
  closeBtn: {
    color: 'white',
    transition: '0.2s opacity ease-in-out',
    '&:hover': {
      opacity: 0.8,
    },
  },
  listItem: {
    '&:hover': {
      backgroundColor: 'rgba(255,255,255,0.1)',
    },
  },
  divider: {
    background: '#707070',
    height: '1px',
  },
  logo: {
    width: '55px',
    height: '55px',
  },
  logoNav: {
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  nav: {
    marginLeft: '1rem',
  },
  linkNav: {
    fontSize: '1.4rem',
    color: 'white',
    fontWeight: 500,
    margin: '0 2rem',
    textDecoration: 'none',
    transition: '0.2s opacity ease-in-out',
    '&:hover': {
      opacity: 0.8,
    },
  },
  linkNavActive: {
    color: '#e5ba57 !important',
  },
  socialConnect: {
    display: 'flex',
    alignItems: 'center',
  },
  socialMedia: {
    display: 'flex',
    alignItems: 'center',
  },
  btnConnect: {
    marginLeft: '1rem',
  },
  textBtnConnect: {
    fontSize: '1rem !important',
  },
  btnNoPointerEvent: {
    pointerEvent: 'none',
    cursor: 'initial',
    '&:hover': {
      opacity: 1,
    },
  },
  containerInfo: {
    marginTop: '2rem',
    paddingLeft: '1.6rem',
    paddingRight: '1.6rem',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  textInfo: {
    textTransform: 'uppercase',
    color: 'white',
    fontSize: '1.4rem',
    textAlign: 'center',
  },
  bigBtn: {
    fontSize: '1.4rem',
    marginTop: '2rem',
    width: '16rem',
  },
  listText: {
    fontSize: '1.4rem',
  },
}));
