import { withStyles } from '@material-ui/core';
import bg from '../../../assets/bg-no-connected.jpg';
import { heightHeader, heightFooter } from '../../../constants/size';

export default withStyles(({ palette, breakpoints }) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    minHeight: `calc(100vh - ${heightFooter} - 8rem)`,
  },
  bg: {
    backgroundImage: `url(${bg})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    width: '100vw',
    height: `calc(100vh - ${heightFooter} - 8rem)`,
  },
  wrapperNoConnected: {
    width: '100%',
    height: `calc(100vh - ${heightFooter} - ${heightHeader} - 8rem)`,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    alignItems: 'center',
  },
  title: {
    textTransform: 'uppercase',
    color: palette.common.white,
    textShadow: '2px 9px 0 #10193A70',
    fontSize: '6rem',
    [breakpoints.down(600)]: {
      fontSize: '3rem',
    },
  },
  subTitle: {
    textTransform: 'uppercase',
    color: palette.common.white,
    textShadow: '4px 3px 0 #10193A70',
    fontSize: '3rem',
    [breakpoints.down(600)]: {
      fontSize: '2rem',
    },
  },
  btnConnect: {
    marginTop: '1rem',
  },
  container: {
    padding: '3rem',
  },
}));
