import { withStyles } from '@material-ui/core';
import bg from '../../../assets/bg-no-connected.jpg';
import { heightFooter, heightHeader } from '../../../constants/size';

export default withStyles(({ palette, breakpoints }) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    minHeight: `calc(100vh - ${heightFooter} - 8rem)`,
  },
  bg: {
    backgroundImage: `url(${bg})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    width: '100vw',
    height: `calc(100vh - ${heightFooter} - 8rem)`,
  },
  wrapperNoConnected: {
    width: '100%',
    height: `calc(100vh - ${heightFooter} - ${heightHeader} - 8rem)`,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    alignItems: 'center',
  },
  containerLoading: {
    margin: 'auto',
    width: '50vw',
    height: '100%',
  },
  loadingLogo: {
    width: '100%',
    height: '100%',
  },
  noPointerEvent: {
    pointerEvent: 'none',
  },
  title: {
    textTransform: 'uppercase',
    color: palette.common.white,
    textShadow: '2px 9px 0 #10193A70',
    fontSize: '6rem',
    [breakpoints.down(600)]: {
      fontSize: '3rem',
    },
  },
  subTitle: {
    textTransform: 'uppercase',
    color: palette.common.white,
    textShadow: '4px 3px 0 #10193A70',
    fontSize: '3rem',
    [breakpoints.down(600)]: {
      fontSize: '2rem',
    },
  },
  classNameModalRewards: {
    display: 'grid',
    alignItems: 'center',
  },
  paperRewards: {
    height: 'fit-content',
  },
  bodyModal: {
    display: 'flex',
    flexDirection: 'column',
    gap: '2rem',
    margin: '2rem',
  },
  bodyModalStaked: {
    flexDirection: 'row',
    margin: '2rem auto',
    width: '80%',
    alignItems: 'center',
  },
  bodyModalInfoRewards: {
    color: 'white',
  },
  wrapperTextInfos: {
    display: 'flex',
    flexDirection: 'row',
    margin: '2rem auto',
    width: '80%',
    alignItems: 'center',
  },
  titleInfoRewards: {
    color: palette.secondary.main,
    textAlign: 'center',
    fontSize: '3rem',
  },
  descriptionInfoRewards: {
    marginTop: '2rem',
    whiteSpace: 'pre-wrap',
    fontSize: '1.4rem',
    lineHeight: '3rem',
    textAlign: 'center',
  },
  textInfoRewards: {
    whiteSpace: 'pre-wrap',
    fontSize: '1.2rem',
    lineHeight: '4rem',
    textAlign: 'center',
  },
  staked: {
    flex: 2,
  },
  unStaked: {
    flex: 2,
  },
  dividerModal: {
    width: '2px',
    height: '8rem',
    background: '#005F89',
    margin: '0 2rem',
  },
  titleBodyModal: {
    fontSize: '1.6rem',
    color: 'white',
    textTransform: 'uppercase',
    textAlign: 'center',
  },
  textBodyModal: {
    fontSize: '1.2rem',
    color: 'white',
    textTransform: 'uppercase',
    textAlign: 'center',
  },
  btnConnect: {
    marginTop: '1rem',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  wrapperInfos: {
    display: 'flex',
    justifyContent: 'center',
    gap: '2rem',
    rowGap: '2rem',
    flexWrap: 'wrap',
    padding: '0 2rem',
  },
  cardInfoText: {
    color: 'white',
    fontSize: '2rem',
    maxWidth: '90%',
    whiteSpace: 'pre-wrap',
    fontWeight: 800,
  },
  listNfts: {
    width: '90%',
    margin: '2rem auto',
    paddingBottom: '2rem',
    marginBottom: 0,
  },
  video: {
    width: '100%',
  },
  wrapperCardRewards: {
    marginTop: '2rem',
    display: 'flex',
    gap: '2rem',
    flexWrap: 'wrap',
    columnGap: '2rem',
    justifyContent: 'center',
    alignItems: 'center',
  },
  textCardReward: {
    color: 'white',
    fontSize: '1.6rem',
    textAlign: 'center',
  },
  wrapperSocial: {
    display: 'flex',
    justifyContent: 'center',
  },
  paperNoBackground: {
    border: 'none',
    background: 'none',
    boxShadow: 'none',
    margin: '2rem',
  },
}));
