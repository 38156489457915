import React from 'react';
import ReactDOM from 'react-dom';
import { Route, BrowserRouter } from 'react-router-dom';
import './index.css';
import { CssBaseline, MuiThemeProvider } from '@material-ui/core';
import AppRoutes from './routes';
import theme from './themes/muiTheme';

ReactDOM.render(
  <BrowserRouter>
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      <Route component={AppRoutes} />
    </MuiThemeProvider>
  </BrowserRouter>,
  document.getElementById('root'),
);
