import { Alchemy } from 'alchemy-sdk';
import unrevealLand from '../assets/prereveal-lands.gif';
import { contractLandNft, contractOperatorLand, contractSaleLand } from './contract';
import { getLandByTokenIdAndSize } from '../api/land';
// eslint-disable-next-line import/no-cycle

const settings = {
  apiKey: process.env.REACT_APP_ALCHEMY_PROVIDER,
  network: process.env.REACT_APP_ALCHEMY_NETWORK,
};

const alchemy = new Alchemy(settings);

export const numberOfStakeLands = async (eth, cbSuccess = () => {}) => {
  const landNftContract = new eth.Contract(
    contractLandNft.abi,
    contractLandNft.address,
  ); // 15709958

  const staked = await landNftContract.getPastEvents('Staked', {
    fromBlock: process.env.REACT_APP_BEGIN_BLOCK_LANDS,
    toBlock: 'latest',
  });
  const unStaked = await landNftContract.getPastEvents('Unstaked', {
    fromBlock: process.env.REACT_APP_BEGIN_BLOCK_LANDS,
    toBlock: 'latest',
  });

  const realStake = staked.filter(
    (event) => !unStaked.find(
      (unEvent) => unEvent.returnValues.tokenId === event.returnValues.tokenId
            && unEvent.returnValues.timeframe >= event.returnValues.timeframe,
    ),
  );

  const operatorLandContract = new eth.Contract(
    contractOperatorLand.abi,
    contractOperatorLand.address,
  );

  const newStaked = await operatorLandContract.getPastEvents('X1Staked', {
    fromBlock: process.env.REACT_APP_BEGIN_BLOCK_LANDS,
    toBlock: 'latest',
  });
  const newUnStaked = await operatorLandContract.getPastEvents('X1Unstaked', {
    fromBlock: process.env.REACT_APP_BEGIN_BLOCK_LANDS,
    toBlock: 'latest',
  });

  const realNewStake = newStaked.filter(
    (event) => !newUnStaked.find(
      (unEvent) => unEvent.returnValues.tokenId === event.returnValues.tokenId
            && unEvent.returnValues.timeframe >= event.returnValues.timeframe,
    ),
  );

  cbSuccess(realStake.length + realNewStake.length);
  return realStake.length + realNewStake.length;
};

export const getRealNewStakeLand = async (
  eth,
  cbSuccess = () => {},
) => {
  const operatorLandContract = new eth.Contract(
    contractOperatorLand.abi,
    contractOperatorLand.address,
  );

  const staked = await operatorLandContract.getPastEvents('X1Staked', {
    fromBlock: process.env.REACT_APP_BEGIN_BLOCK_OPERATOR_LANDS,
    toBlock: 'latest',
  });
  const unStaked = await operatorLandContract.getPastEvents('X1Unstaked', {
    fromBlock: process.env.REACT_APP_BEGIN_BLOCK_OPERATOR_LANDS,
    toBlock: 'latest',
  });

  const realStake = staked.filter(
    (event) => !unStaked.find(
      (unEvent) => unEvent.returnValues.tokenId === event.returnValues.tokenId
          && unEvent.returnValues.timeframe >= event.returnValues.timeframe,
    ),
  );

  cbSuccess(realStake);
  return realStake;
};

export const isLotteryActive = async (eth, account, cbSuccess = () => {}) => {
  const landSaleContract = new eth.Contract(
    contractSaleLand.abi,
    contractSaleLand.address,
  );

  const lotteryActive = await landSaleContract.methods.isLotteryActive().call();
  const activeSale = await landSaleContract.methods.isActive().call();
  let whitelistLottery = 0;
  let publicLottery = 0;
  if (lotteryActive) {
    for (let i = 0; i <= 6; i += 1) {
      // eslint-disable-next-line no-await-in-loop
      const tmp = await landSaleContract.methods.chanceToWinPerAddress(account, i).call();
      // eslint-disable-next-line no-await-in-loop
      const tmp2 = await landSaleContract.methods.chanceToWinPerAddressWhitelist(account, i).call();
      whitelistLottery += parseInt(tmp2, 10);
      publicLottery += parseInt(tmp, 10);
    }
  }
  cbSuccess({ lotteryActive: lotteryActive && activeSale === false, whitelistLottery, publicLottery });
  return { lotteryActive: lotteryActive && activeSale === false, whitelistLottery, publicLottery };
};

export const lottery = async (eth, account, cbSuccess, cbError) => {
  const landSaleContract = new eth.Contract(
    contractSaleLand.abi,
    contractSaleLand.address,
  );

  landSaleContract.methods.lottery().send({ from: account })
    .then((elem) => {
      cbSuccess(elem);
    })
    .catch((error) => {
      cbError(error);
    });
};
export const getSupplyLands = async (eth, cbSuccess = () => {}) => {
  const landNftContract = new eth.Contract(
    contractLandNft.abi,
    contractLandNft.address,
  );

  const totalSupply = await landNftContract.methods.totalSupply().call();
  const supplyBurn = await landNftContract.methods.balanceOf(process.env.REACT_APP_ADDRESS_NULL_OPERATOR).call();
  const realTotalSupply = parseInt(totalSupply, 10) - parseInt(supplyBurn, 10);
  cbSuccess(realTotalSupply);
  return realTotalSupply;
};

export const getLandStakedOwner = async (eth, account) => {
  const realStakeLands = await getRealNewStakeLand(eth);

  realStakeLands.sort(
    (a, b) => a.returnValues.blockNumber - b.returnValues.blockNumber,
  );
  const realStakeOwner = realStakeLands.filter(
    (event) => event.returnValues?.owner?.toLowerCase() === account?.toLowerCase(),
  );

  return realStakeOwner;
};

export const getNumberLandsNoStakedOwner = async (eth, account) => {
  const landNftContract = new eth.Contract(
    contractLandNft.abi,
    contractLandNft.address,
  );

  const nbTokensNoStake = await landNftContract.methods
    .balanceOf(account)
    .call();

  return nbTokensNoStake;
};

export const getLandNft = async (eth, account, cbSuccess = () => {}) => {
  const landNftContract = new eth.Contract(
    contractLandNft.abi,
    contractLandNft.address,
  );

  const nfts = await alchemy.nft.getNftsForOwner(account, { contractAddresses: [contractLandNft.address.toLowerCase()] });
  const { ownedNfts } = await nfts;
  const tokens = [];

  for (let i = 0; i < ownedNfts.length; i += 1) {
    const { tokenId } = ownedNfts[i];
    // eslint-disable-next-line no-await-in-loop
    const isStaked = await landNftContract.methods.isStaked(tokenId).call(); // OLD MECANISM OF STAKED
    tokens.push({
      tokenId,
      image: '',
      isStake: isStaked,
      tokenOwner: account,
    });
  }

  const realStakeOwner = await getLandStakedOwner(eth, account);

  const tokensNewStake = realStakeOwner.map((event) => ({
    tokenId: event.returnValues.tokenId,
    image: unrevealLand,
    isStake: true,
    tokenOwner: event.returnValues.owner,
  }));
  /* const totalSupply = await landNftContract.methods
    .totalSupply()
    .call();

  for (let i = 0; i < totalSupply; i += 1) {
    // eslint-disable-next-line no-await-in-loop
    const owner = await landNftContract.methods.ownerOf(i).call();
    if (owner.toLowerCase() === account.toLowerCase()) {
      // eslint-disable-next-line no-await-in-loop
      const isStaked = await landNftContract.methods.isStaked(i).call();
      tokens.push({
        tokenId: i,
        image: unrevealLand,
        isStake: isStaked,
        tokenOwner: account,
      });
    }
  } */
  const allTokens = tokens.concat(tokensNewStake).reverse();
  for (let i = 0; i < allTokens.length; i += 1) {
    // eslint-disable-next-line no-await-in-loop
    const landInDb = await getLandByTokenIdAndSize(allTokens[i].tokenId, 1);
    allTokens[i].image = `${process.env.REACT_APP_PATH_S3_LANDS}/lands-compressed/${landInDb.thumbnailId}.png`;
  }
  cbSuccess(allTokens);
  return allTokens;
};
