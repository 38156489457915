import React from 'react';
import { ConnectButton } from '@rainbow-me/rainbowkit';
import { makeStyles } from '@material-ui/core';
import ButtonPerso from '../ButtonPerso/button-perso';

export default function ConnectWallet() {
  const classes = useStyles();
  return (
    <ConnectButton.Custom>
      {({
        account,
        chain,
        openAccountModal,
        openChainModal,
        openConnectModal,
        mounted,
      }) => {
        // Note: If your app doesn't use authentication, you
        // can remove all 'authenticationStatus' checks
        const ready = mounted;
        const connected = ready
          && account
          && chain;
        return (
          <div
            {...(!ready && {
              'aria-hidden': true,
              style: {
                opacity: 0,
                pointerEvents: 'none',
                userSelect: 'none',
              },
            })}
          >
            {(() => {
              if (!connected) {
                return (
                  <ButtonPerso
                    text="Connect your wallet"
                    className={classes.btnConnect}
                    classNameText={classes.textBtnConnect}
                    onClick={openConnectModal}
                  />
                );
              }

              if (chain.unsupported) {
                return (
                  <ButtonPerso
                    text="Wrong network"
                    className={classes.btnConnect}
                    classNameText={classes.textBtnConnect}
                    onClick={openChainModal}
                  />
                );
              }

              return (
                <ButtonPerso
                  text={account.displayName}
                  className={classes.btnConnect}
                  classNameText={classes.textBtnConnect}
                  onClick={openAccountModal}
                />
              );
            })()}
          </div>
        );
      }}
    </ConnectButton.Custom>
  );
}

const useStyles = makeStyles(() => ({
  btnConnect: {
    marginLeft: '1rem',
  },
  textBtnConnect: {
    fontSize: '1rem !important',
  },
}));
