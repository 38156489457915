import React from 'react';
import Proptypes from 'prop-types';

import { Box } from '@material-ui/core';
import withTerms from './terms.style';
import Header from '../../_organisms/Header/header';

function Terms({ classes }) {
  return (
    <div>
      <Header displayConnect={false} />
      <Box className={classes.container}>
        <h1>Terms &amp; Conditions</h1>
        <Box className={classes.content}>
          <h2>1. Organization</h2>
          <p>
            Oxya is a French company, whose official offices are located at 229 rue
            Saint Honoré, 75001 Paris and is registered with the Registre du
            Commerce et des Sociétés of Paris under number 911 609 394 under the
            representation of Ms. Greg Taieb, in her capacity as General Director
            (hereinafter
            {' '}
            <strong>&quot;Oxya&quot;</strong>
            ).
          </p>
          <p>
            Oxya is a Web 3.0 gaming studio currently developing the Gates of Oxya,
            an open world video-game consisting of various modes and a collectible
            game asset ecosystem backed by a double token economy (subject to
            transition to immutable blockchain). Players can own in-game items and
            use them to progress through the story or trade with other players on
            {' '}
            <a href="www.oxyaorigin.com">www.oxyaorigin.com</a>
            {' '}
            by using “$OXYA”, an Ethereum-based token using
            ERC-20 standard (the
            {' '}
            <strong>&quot;Project&quot;</strong>
            ).
          </p>
          <p>
            Oxya holds various iterations or “Seasons” of an online contest entitled
            “STAKING” (hereinafter referred to as the &quot;Contest&quot;) accessible on the
            website:
            {' '}
            <a href="https://staking.oxyaorigin.com/">https://staking.oxyaorigin.com/</a>
            {' '}
            (hereinafter the &quot;Site&quot;).
          </p>
          <p>
            Each Season runs for a predetermined amount of time, subject to
            extension but not curtailment.
          </p>
          <p>
            Rewards shall be allocated to Oxya Origin in-game asset owners which
            they will be able to claim depending on the Season’s stipulations.
          </p>
        </Box>
        <Box className={classes.content}>
          <h2>2. Conditions of participation / eligibility</h2>
          <p>
            Participation in the Contest implies the express and unreserved
            acceptance of the present rules, its stipulations, rules of ethics in force on
            the Internet, and laws and regulations applicable in France. Any dispute
            concerning its interpretation will be decided sovereignly by Oxya and
            without appeal.
          </p>
          <p>
            This Contest is exclusively opened to individuals, to the exclusion of legal
            entities.
          </p>
          <p>
            This Contest is exclusively open to adults and excludes individuals under
            18 years old. Any participant who is a minor must necessarily have
            requested and obtained parental authorization before registering for the
            Contest. This parental authorization may be required by Oxya before the
            prize is awarded. Oxya reserves the right not to take into account the
            participation of a minor who does not provide parental authorization within
            the required time limit.
          </p>
          <p>
            Oxya reserves the right to ask any user to justify these conditions. Any
            person who does not meet these conditions or who refuses to prove it will
            be excluded from the Contest and will not be able to receive a prize in
            case of a win.
          </p>
          <p>
            Participation is strictly personal, and the user may under no
            circumstances play on behalf of other users. Proof of identity and
            residence may be requested at any time during the game. Any
            participation in the Contest that is incomplete, sent after the deadline or in
            a form other than that provided for will be considered null and void. Oxya
            reserves the right to carry out any verification to ensure compliance with
            this rule.
          </p>
        </Box>
        <Box className={classes.content}>
          <h2>3. Details of participation</h2>
          <p>
            Each participant must connect their MetaMask to the official Oxya
            website.
            {' '}
            <br />
            The participant must log on to the Oxya Dashboard and stake their Oxya
            game assets.
            {' '}
            <br />
            Only staked game assets will be taken into account for the reward
            distribution.
          </p>
          <p>
            In general, the participant of the Contest guarantees Oxya against all
            recourse, actions or claims that could be made, in any capacity
            whatsoever, by any third party, on the occasion of the exercise of the
            rights assigned to the present agreement and, more generally, on the
            basis of all the guarantees and commitments made under the present
            agreement, and consequently undertakes to bear all the costs, damages,
            fees and disbursements, as time goes by, suffered by Oxya. Oxya
            reserves the right to carry out any verification in application of the present
            rules.
          </p>
        </Box>
        <Box className={classes.content}>
          <h2>4. Prizes and designation of the winners</h2>
          <p>
            Each Season of the Contest will be uniquely structured in terms of length,
            required winner qualifications, prize pool size and distribution.
            A Contest Season may span two (2) to sixty-four (64) weeks which will be
            announced publicly in anticipation to the start.
          </p>
          <p>
            Winner qualifications and prize distribution may differ from Season to
            Season and will take one or more of these factors into account: OXYA
            Federation rank, number of Oxya game assets staked, Oxya asset rarity,
            $OXYA token and in-game interactions to be made available in the future.
          </p>
          <p>
            The Contest prize pool may include one or more prize categories
            including but not limited to $ETH, $OXYA, $BTC, $USD, Oxya game
            assets, gifts from partners etc…
          </p>
        </Box>
        <Box className={classes.content}>
          <h2>5. Awarding of Prizes</h2>
          <p>
            If the information or contact details communicated by the participant do
            not allow them to be informed of their win, they will lose their status and
            will not be able to make any claim.
          </p>
          <p>
            If the winner is unable to benefit, in whole or in part, from their prize, for
            whatever reason, they will lose the benefit, without the possibility of
            obtaining any compensation.
          </p>
          <p>
            Winnings may not be redeemed in cash or for consideration of any kind at
            the request of a winner, nor may they be replaced by a winning of an
            equivalent nature.
          </p>
        </Box>
        <Box className={classes.content}>
          <h2>6. Protection of personal data</h2>
          <p>
            The personal data of the participants will be processed by Oxya, as data
            controller, for the processing of their participation in the Contest and their
            registration on the application, in accordance with European Regulation
            No. 2016/679 on the protection of personal data (RGPD).
          </p>
          <p>
            The data transfers made necessary shall take place under conditions and
            guarantees that ensure the protection of the personal data collected.
          </p>
          <p>
            Participants have a right of access and communication of personal data
            concerning them, as well as the right to obtain the rectification or deletion
            of inaccurate data, or to object to the processing of such data for
            legitimate reasons.
          </p>
          <p>
            These rights may be exercised with Oxya:
            • By email to the address;
            • By post to the attention of Oxya, 229 rue Saint Honoré, 75001
            Paris.
          </p>
        </Box>
        <Box className={classes.content}>
          <h2>7. Contest Rules</h2>
          <p>
            The Contest rules may be sent to any person who requests them. Such
            request should be addressed to Oxya, 229 rue Saint Honoré, 75001 Paris.
          </p>
        </Box>
        <Box className={classes.content}>
          <h2>8. Responsibilites</h2>
          <p>
            Oxya reserves the right to disqualify any participant who alters the
            Contest functioning or the Site. It also applies if the participant violates the
            official Contest rules. Oxya reserves the right to prosecute anyone who
            attempts to defraud or harm the proper Contest functioning.
          </p>
          <p>
            Oxya reserves the right to extend, shorten, modify or cancel the Contest
            at any time, in particular in the event of force majeure, or events beyond
            its control, without the participants being entitled to claim any
            compensation. Any Participant refusing the modification(s) made must
            cease to participate in the Contest.
          </p>
          <p>
            If for any reason whatsoever, this Contest should not take place as
            planned because of a computer virus, a bug, an intervention, or an
            external and unauthorised intrusion into the computer system, a fraud,
            including the use of a robot to multiply the number of entries in the
            Contest, or a technical failure or any other reason beyond the control of
            Oxya. It also applies if a situation is corrupting or affecting the
            management, security, fairness or proper conduct of the Contest, Oxya
            reserves the discretionary right to cancel, modify or suspend the Contest
            or to terminate it without delay.
          </p>
          <p>
            Oxya may decide to cancel the Contest or a participation if it appears that
            obvious fraud has occurred in any form whatsoever, as in a computerized
            manner, in the context of the Contest participation or the determination of
            the winners.
          </p>
          <p>
            Participation via the Internet implies knowledge and acceptance of the
            characteristics and limits of the Internet, in particular with regard to the
            lack of protection of certain data against possible misappropriation or
            piracy and the risks of contamination by possible viruses circulating on the
            network, technical performance, response times for consulting, querying
            or transferring information, the risks of interruption and, more generally,
            the risks inherent in any connection and transmission on the Internet.
            Oxya declines all responsibility, without this list being limitative, in the
            event of misuse or incident linked to the use of the computer, of any
            malfunction of the Internet network, of the Contest servers, of the
            telephone line or of any other technical connection preventing the proper
            running of the Contest.
          </p>
        </Box>
        <Box className={classes.content}>
          <h2>9. Governing Law - Jurisdiction</h2>
          <p>
            These rules shall be governed by French law. Only the English version of
            these rules shall prevail.
          </p>
          <p>
            Oxya reserves the right to decide without appeal any difficulty that may
            arise as to the interpretation or application of these rules. No dispute will
            be admitted, in particular on the Contest terms, the results, or the prizes,
            after Sunday 19th of June 2022.
          </p>
          <p>
            M. Greg Taieb
          </p>
        </Box>
      </Box>
    </div>
  );
}

Terms.propTypes = {
  classes: Proptypes.objectOf(
    Proptypes.string,
  ).isRequired,
};

export default withTerms(Terms);
