import React, { useEffect } from 'react';
import cx from 'classnames';
import {
  getDefaultWallets,
  RainbowKitProvider,
  midnightTheme,
} from '@rainbow-me/rainbowkit';

import {
  configureChains, createConfig, WagmiConfig,
} from 'wagmi';
import { infuraProvider } from 'wagmi/providers/infura';

import { mainnet } from 'wagmi/chains';

import '@rainbow-me/rainbowkit/styles.css';
import merge from 'lodash.merge';

import WebFont from 'webfontloader';

import Proptypes from 'prop-types';
import withApp from './App.style';
import SocialMedia from '../../components/_organisms/SocialMedia/social-media';
import Footer from '../../components/_organisms/Footer/footer';

/* const authenticationAdapter = createAuthenticationAdapter({
  getNonce: async () => {
    const response = await getNonce();
    return response;
  },
  createMessage: ({ nonce, address, chainId }) => new SiweMessage({
    domain: window.location.host,
    address,
    statement: 'Sign in with Ethereum to the app.',
    uri: window.location.origin,
    version: '1',
    chainId,
    nonce,
  }),
  getMessageBody: ({ message }) => message.prepareMessage(),
  verify: async ({ message, signature }) => {
    const verifyRes = await verify(message, signature);
    return Boolean(verifyRes.ok);
  },
  signOut: async () => {
    await logout();
  },
}); */

const projectId = process.env.REACT_APP_WALLETCONNECT_ID;

const { chains, publicClient } = configureChains(
  [mainnet],
  [infuraProvider({ apiKey: process.env.REACT_APP_INFURA_ID })],
);

const { connectors } = getDefaultWallets({
  appName: 'Oxya Origin',
  projectId,
  chains,
});

const wagmiConfig = createConfig({
  autoConnect: false,
  connectors,
  publicClient,
});

const myTheme = merge(midnightTheme(), {
  colors: {
    accentColor: '#00DBFF',
    accentColorForeground: 'white',
  },
  fonts: {
    body: 'Montserrat, sans-serif',
  },
});

function App({ classes, children }) {
  useEffect(() => {
    WebFont.load({
      google: {
        families: ['Montserrat:100,100italic,200,200italic,300,300italic,400,400italic,500,500italic,600,600italic,700,700italic,800,800italic,900,900italic'],
      },
    });
  });
  return (
    <WagmiConfig config={wagmiConfig}>
      <RainbowKitProvider chains={chains} theme={myTheme}>
        <div className={cx(classes.app)}>
          <main>
            {children}
          </main>
          <Footer />
        </div>
        <SocialMedia className={classes.socialMedia} />
      </RainbowKitProvider>
    </WagmiConfig>

  );
}

App.propTypes = {
  classes: Proptypes.objectOf(
    Proptypes.string,
  ).isRequired,
  children: Proptypes.node,
};

App.defaultProps = {
  children: null,
};

export default withApp(App);
