import { withStyles } from '@material-ui/core';

export default withStyles(({ palette }) => ({
  root: {
    position: 'relative',
    overflow: 'hidden',
    padding: '2rem',
    display: 'flex',
    width: '22rem',
    height: '22rem',
    justifyContent: 'space-around',
    alignItems: 'center',
    flexDirection: 'column',
    border: 'none',
    background: (props) => (props.background ? `url(${props.background})` : 'rgb(26, 64, 98, 0.4)'),
    backgroundSize: (props) => (props.background) && 'cover',
    boxShadow: '0 14px 23px 4px rgba(0, 0, 0, 0.03)',
    borderTopRightRadius: (props) => props.firstRadius && '2rem',
    borderBottomLeftRadius: (props) => props.firstRadius && '2rem',
    borderTopLeftRadius: (props) => props.firstRadius === false && '2rem',
    borderBottomRightRadius: (props) => props.firstRadius === false && '2rem',
    transition: '0.2s opacity ease-in-out',
    cursor: 'pointer',
    '&:hover': {
      opacity: 0.8,
    },
  },
  checkbox: {
    position: 'absolute',
    top: '-9px',
    right: '-9px',
    color: palette.secondary.light,
    '&:hover': {
      background: 'transparent',
    },
  },
  iconCheckbox: {
    borderRadius: 3,
    width: 12,
    height: 12,
    border: '0.5px solid #E5BA57',
    boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    transition: '0.2s opacity ease-in-out',
    'input:hover ~ &': {
      opacity: 0.8,
    },
  },
  checkedIconCheckbox: {
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 12,
      height: 12,
      background: '#F1DA80',
      maskImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 19 19'%3E%3Cpath"
        + " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 "
        + "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
      content: '""',
    },
  },
}));
