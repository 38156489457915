/* eslint-disable jsx-a11y/media-has-caption */
import React from 'react';
import cx from 'classnames';
import Proptypes from 'prop-types';

import { Button } from '@material-ui/core';
import withToggleButtons from './toggle-buttons.style';

function ToggleButtons({
  classes, className, tabs, activeTab, onClick,
}) {
  return (
    <div className={cx(classes.root, className)}>
      {
            tabs.map((tab) => (
              <Button
                onClick={onClick(tab.key)}
                key={`tab-${tab.key}`}
                className={cx(classes.btn, activeTab === tab.key && classes.btnActive)}
                id={`tab-${tab.key}`}
                classes={{ label: classes.textBtn }}
              >
                {tab.label}
              </Button>
            ))
        }
    </div>
  );
}

ToggleButtons.propTypes = {
  classes: Proptypes.objectOf(
    Proptypes.string,
  ).isRequired,
  className: Proptypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  tabs: Proptypes.array,
  onClick: Proptypes.func,
  activeTab: Proptypes.number,
};

ToggleButtons.defaultProps = {
  className: '',
  tabs: [{
    key: 0,
    label: 'Delivered',
  }, {
    key: 1,
    label: 'Pending',
  }],
  activeTab: 1,
  onClick: () => {},
};

export default withToggleButtons(ToggleButtons);
