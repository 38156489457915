import React from 'react';
import Proptypes from 'prop-types';
import { Redirect } from 'react-router-dom/cjs/react-router-dom.min';

function BeAdmin({
  account,
  children,
  accessToken,
  roles,
}) {
  return (
    <>
      {
        account && accessToken && roles?.includes('Admin') && (
          children
        )
      }
      {
        account && accessToken && roles?.includes('Admin') === false && roles?.includes('User') && (
          <Redirect to="/" />
        )
      }
    </>
  );
}

BeAdmin.propTypes = {
  account: Proptypes.string,
  children: Proptypes.node,
  accessToken: Proptypes.string,
  roles: Proptypes.arrayOf(Proptypes.string),
};

BeAdmin.defaultProps = {
  account: null,
  children: null,
  accessToken: null,
  roles: [],
};
export default BeAdmin;
