import React from 'react';
import cx from 'classnames';
import Proptypes from 'prop-types';

import { Checkbox } from '@material-ui/core';
import withCardOxya from './card-oxya.style';

function CardOxya({
  classes, className, children, background, checked, onChangeChecked,
}) {
  return (
    <button type="button" onClick={onChangeChecked} className={cx(classes.root, className)}>
      {
        background && (
          <Checkbox
            className={classes.checkbox}
            icon={<span className={classes.iconCheckbox} />}
            checkedIcon={<span className={cx(classes.iconCheckbox, classes.checkedIconCheckbox)} />}
            checked={checked}
          />
        )
      }
      {children}
    </button>
  );
}

CardOxya.propTypes = {
  classes: Proptypes.objectOf(
    Proptypes.string,
  ).isRequired,
  onChangeChecked: Proptypes.func,
  className: Proptypes.string,
  background: Proptypes.string,
  checked: Proptypes.bool,
  children: Proptypes.node,
};

CardOxya.defaultProps = {
  className: '',
  children: null,
  background: null,
  checked: false,
  onChangeChecked: () => {},
};

export default withCardOxya(CardOxya);
