import React from 'react';
import Proptypes from 'prop-types';
import cx from 'classnames';

import {
  Snackbar, SnackbarContent, Slide, makeStyles, IconButton,
} from '@material-ui/core';

import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';
import WarningIcon from '@material-ui/icons/Warning';
import { amber, green } from '@material-ui/core/colors';

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
};

const useStyles = makeStyles(({ palette, spacing }) => ({
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: palette.error.dark,
    display: 'flex',
    justifyContent: 'center',
  },
  info: {
    backgroundColor: palette.primary.main,
  },
  warning: {
    backgroundColor: amber[700],
  },
  icon: {
    fontSize: 20,
  },
  iconBtn: {
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: spacing(1),
  },
  message: {
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    fontSize: '1.4rem',
    flexWrap: 'no-wrap',
  },
}));

const transition = (props) => (
  <Slide {...props} direction="up" />
);

function SnackBarBottom({
  text, open, className, onClose, variant, ...other
}) {
  const classes = useStyles();
  const Icon = variantIcon[variant];
  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      open={open}
      onClose={onClose}
      TransitionComponent={transition}
      autoHideDuration={5000}
      ContentProps={{
        'aria-describedby': 'message-feedback-wrapper',
      }}
    >
      <SnackbarContent
        className={cx(classes[variant], className)}
        aria-describedby="message-feedback-content"
        message={(
          <span id="message-feedback" className={classes.message}>
            <Icon className={cx(classes.icon, classes.iconVariant)} />
            {text}
          </span>
      )}
        action={[
          <IconButton key="close" aria-label="close" color="inherit" onClick={onClose} className={classes.iconBtn}>
            <CloseIcon className={classes.icon} />
          </IconButton>,
        ]}
        {...other}
      />
    </Snackbar>
  );
}

SnackBarBottom.propTypes = {
  text: Proptypes.string,
  open: Proptypes.bool,
  onClose: Proptypes.func,
  className: Proptypes.string,
  variant: Proptypes.string,
};

SnackBarBottom.defaultProps = {
  text: '',
  open: false,
  onClose: () => {},
  className: '',
  variant: 'success',
};

export default SnackBarBottom;
