/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Route, Switch } from 'react-router-dom';

import App from './containers/App/App';
import Oxyans from './components/_pages/Oxyans/oxyans';
import Plots from './components/_pages/Plots/plots';
import AdminStaking from './components/_pages/AdminStaking/admin-staking';
import Colonies from './components/_pages/Colonies/colonies';
import Terms from './components/_pages/Terms/terms';
import Rewards from './components/_pages/Rewards/rewards';
import adminGatemap from './components/_pages/AdminGatemap/admin-gatemap';

function AppRoutes(props) {
  return (
    <App {...props}>
      <Switch>
        <Route exact path="/" component={Rewards} />
        <Route exact path="/plots" component={Plots} />
        <Route exact path="/colonies" component={Colonies} />
        <Route exact path="/oxyans" component={Oxyans} />
        <Route exact path="/admin" component={AdminStaking} />
        <Route exact path="/admin/staking" component={AdminStaking} />
        <Route exact path="/admin/gatemap" component={adminGatemap} />
        <Route exact path="/terms" component={Terms} />
        <Route component={Rewards} />
      </Switch>
    </App>
  );
}

export default AppRoutes;
