import { withStyles } from '@material-ui/core';

export default withStyles(({ palette }) => ({
  root: {
    border: 'solid 1px #27c6f7',
    padding: '3rem',
    position: 'relative',
    overflow: 'scroll',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    boxShadow: '0 0 30px #27c6f74d',
    background: 'rgba(26, 64, 98, 0.3)',
    borderRadius: '2rem',
  },
  header: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
  leftHeader: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    marginLeft: '1rem',
    transition: '0.2s opacity ease-in-out',
    '&:hover': {
      cursor: 'pointer',
      opacity: 0.8,
    },
  },
  titleHeader: {
    color: 'white',
    textTransform: 'uppercase',
    fontSize: '3rem',
  },
  textHeader: {
    color: 'white',
    fontSize: '1.4rem',
  },
  btnApply: {
    marginLeft: '2rem',
    background: palette.gradient.btnBlue,
    borderColor: palette.primary.light,
    minWidth: '13rem',
  },
  divider: {
    background: palette.primary.main,
    width: '100%',
    height: '2px',
    margin: '2rem 0',
  },
  body: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '2rem',
    columnGap: '2rem',
  },
  cardInfo: {
    backgroundPosition: 'center',
    backgroundSize: 'contain',
  },
  textNoFound: {
    textTransform: 'uppercase',
    fontSize: '1.6rem',
    color: 'white',
    textAlign: 'center',
  },
  wrapperStakeAll: {
    display: 'flex',
    alignItems: 'center',
  },
  checkbox: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  iconCheckbox: {
    borderRadius: 3,
    width: 18,
    height: 18,
    border: '0.5px solid #E5BA57',
    boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: '#00000040',
    transition: '0.2s opacity ease-in-out',
    'input:hover ~ &': {
      opacity: 0.8,
    },
  },
  checkedIconCheckbox: {
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 18,
      height: 18,
      background: '#F1DA80',
      maskImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 18 18'%3E%3Cpath"
        + " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 "
        + "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
      content: '""',
    },
  },
  wrapperCard: {
    display: 'flex',
    gap: '2rem',
    flexWrap: 'wrap',
    columnGap: '2rem',
    justifyContent: 'center',
    alignItems: 'center',
  },
  textCardNoFound: {
    fontSize: '1.4rem',
    color: 'white',
  },
  btn: {
    background: `transparent linear-gradient(90deg, ${palette.primary.dark} 0%, ${palette.primary.main} 0%, ${palette.primary.light} 100%) 0% 0% no-repeat padding-box`,
    boxShadow: '3px 17px 24px #0A162A6E',
    border: `1px solid ${palette.primary.light}`,
  },
  textBtn: {
    fontSize: '1rem',
  },
  containerCardOxya: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  tokenId: {
    color: 'white',
    fontSize: '1.4rem',
    marginTop: '1rem',
  },
}));
