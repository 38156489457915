import React from 'react';
import Proptypes from 'prop-types';

function ConnectedWallet({
  account,
  children,
  chainId,
  fallback,
  accessToken,
}) {
  return (
    <>
      {
            account && chainId === parseInt(process.env.REACT_APP_CHAIN, 10) && accessToken ? (
              children
            ) : (
              fallback
            )
      }
    </>
  );
}

ConnectedWallet.propTypes = {
  account: Proptypes.string,
  chainId: Proptypes.oneOfType([
    Proptypes.string, Proptypes.number,
  ]),
  children: Proptypes.node,
  fallback: Proptypes.node,
  accessToken: Proptypes.string,
};

ConnectedWallet.defaultProps = {
  chainId: parseInt(process.env.REACT_APP_CHAIN, 10),
  account: null,
  children: null,
  fallback: null,
  accessToken: null,
};
export default ConnectedWallet;
