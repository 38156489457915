import { Alchemy } from 'alchemy-sdk';
import unrevealColony from '../assets/prereveal-colonies.jpeg';
import { contractColonyNft, contractOperatorLand } from './contract';
import { getLandByTokenIdAndSize } from '../api/land';

const settings = {
  apiKey: process.env.REACT_APP_ALCHEMY_PROVIDER,
  network: process.env.REACT_APP_ALCHEMY_NETWORK,
};

const alchemy = new Alchemy(settings);

export const numberOfStakeColonies = async (eth, cbSuccess = () => {}) => {
  const operatorLandContract = new eth.Contract(
    contractOperatorLand.abi,
    contractOperatorLand.address,
  );

  const newStaked = await operatorLandContract.getPastEvents('X2Staked', {
    fromBlock: process.env.REACT_APP_BEGIN_BLOCK_LANDS,
    toBlock: 'latest',
  });
  const newUnStaked = await operatorLandContract.getPastEvents('X2Unstaked', {
    fromBlock: process.env.REACT_APP_BEGIN_BLOCK_LANDS,
    toBlock: 'latest',
  });

  const realNewStake = newStaked.filter(
    (event) => !newUnStaked.find(
      (unEvent) => unEvent.returnValues.tokenId === event.returnValues.tokenId
            && unEvent.returnValues.timeframe >= event.returnValues.timeframe,
    ),
  );

  cbSuccess(realNewStake.length);
  return realNewStake.length;
};

export const getRealNewStakeColony = async (
  eth,
  cbSuccess = () => {},
) => {
  const operatorLandContract = new eth.Contract(
    contractOperatorLand.abi,
    contractOperatorLand.address,
  );

  const staked = await operatorLandContract.getPastEvents('X2Staked', {
    fromBlock: process.env.REACT_APP_BEGIN_BLOCK_OPERATOR_LANDS,
    toBlock: 'latest',
  });
  const unStaked = await operatorLandContract.getPastEvents('X2Unstaked', {
    fromBlock: process.env.REACT_APP_BEGIN_BLOCK_OPERATOR_LANDS,
    toBlock: 'latest',
  });

  const realStake = staked.filter(
    (event) => !unStaked.find(
      (unEvent) => unEvent.returnValues.tokenId === event.returnValues.tokenId
          && unEvent.returnValues.timeframe >= event.returnValues.timeframe,
    ),
  );

  cbSuccess(realStake);
  return realStake;
};

export const getSupplyColonies = async (eth, cbSuccess = () => {}) => {
  const colonyNftContract = new eth.Contract(
    contractColonyNft.abi,
    contractColonyNft.address,
  );

  const totalSupply = await colonyNftContract.methods.totalSupply().call();
  cbSuccess(totalSupply);
  return totalSupply;
};

export const getColonyStakedOwner = async (eth, account) => {
  const realStakeColonies = await getRealNewStakeColony(eth);
  realStakeColonies.sort(
    (a, b) => a.returnValues.blockNumber - b.returnValues.blockNumber,
  );
  const realStakeOwner = realStakeColonies.filter(
    (event) => event.returnValues?.owner?.toLowerCase() === account?.toLowerCase(),
  );

  return realStakeOwner;
};

export const getNumberColoniesNoStakedOwner = async (eth, account) => {
  const colonyNftContract = new eth.Contract(
    contractColonyNft.abi,
    contractColonyNft.address,
  );

  const nbTokensNoStake = await colonyNftContract.methods
    .balanceOf(account)
    .call();

  return nbTokensNoStake;
};

export const getColoniesNft = async (eth, account, cbSuccess = () => {}) => {
  const nfts = await alchemy.nft.getNftsForOwner(account, { contractAddresses: [contractColonyNft.address.toLowerCase()] });
  const { ownedNfts } = await nfts;
  const tokens = [];

  for (let i = 0; i < ownedNfts.length; i += 1) {
    const { tokenId } = ownedNfts[i];
    // eslint-disable-next-line no-await-in-loop
    tokens.push({
      tokenId,
      image: unrevealColony,
      isStake: false,
      tokenOwner: account,
    });
  }
  const realStakeOwner = await getColonyStakedOwner(eth, account);

  const tokensNewStake = realStakeOwner.map((event) => ({
    tokenId: event.returnValues.tokenId,
    image: unrevealColony,
    isStake: true,
    tokenOwner: event.returnValues.owner,
  }));
  /* const totalSupply = await landNftContract.methods
    .totalSupply()
    .call();

  for (let i = 0; i < totalSupply; i += 1) {
    // eslint-disable-next-line no-await-in-loop
    const owner = await landNftContract.methods.ownerOf(i).call();
    if (owner.toLowerCase() === account.toLowerCase()) {
      // eslint-disable-next-line no-await-in-loop
      const isStaked = await landNftContract.methods.isStaked(i).call();
      tokens.push({
        tokenId: i,
        image: unrevealLand,
        isStake: isStaked,
        tokenOwner: account,
      });
    }
  } */
  const allTokens = tokens.concat(tokensNewStake).reverse();

  for (let i = 0; i < allTokens.length; i += 1) {
    // eslint-disable-next-line no-await-in-loop
    const colonyInDb = await getLandByTokenIdAndSize(allTokens[i].tokenId, 2);
    allTokens[i].image = `${process.env.REACT_APP_PATH_S3_LANDS}/colonies-compressed/${colonyInDb.thumbnailId}.png`;
  }
  cbSuccess(allTokens);
  return allTokens;
};
