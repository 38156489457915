import React from 'react';
import Proptypes from 'prop-types';
import cx from 'classnames';
import { makeStyles } from '@material-ui/core';

import openseaLogo from '../../../assets/social/opensea.svg';
import twitterLogo from '../../../assets/social/twitter.svg';
import instaLogo from '../../../assets/social/insta.svg';
import discordLogo from '../../../assets/social/discord.svg';
import youtubeLogo from '../../../assets/social/youtube.svg';
import linkedinLogo from '../../../assets/social/linkedin.svg';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  link: {
    transition: 'transform 200ms ease, color 200ms ease',
    marginBottom: '1rem',
    '&:hover': {
      opacity: 0.6,
      transform: 'scale(0.9)',
    },
    '& img': {
      width: '1.5rem',
    },
  },
}));

export default function SocialMedia({ className }) {
  const classes = useStyles();
  return (
    <div className={cx(classes.container, className)}>
      <a
        href="https://opensea.io/collection/oxyaoriginproject"
        className={classes.link}
        rel="noreferrer"
        target="_blank"
      >
        <img src={openseaLogo} alt="opensea logo" loading="lazy" />
      </a>
      <a
        href="https://opensea.io/collection/gatesofoxya-lands"
        className={classes.link}
        rel="noreferrer"
        target="_blank"
      >
        <img src={openseaLogo} alt="opensea logo" loading="lazy" />
      </a>
      <a
        href="https://opensea.io/fr/collection/gatesofoxyacolony"
        className={classes.link}
        rel="noreferrer"
        target="_blank"
      >
        <img src={openseaLogo} alt="opensea logo" loading="lazy" />
      </a>
      <a
        href="https://www.instagram.com/oxyaorigin/?hl=fr"
        className={classes.link}
        rel="noreferrer"
        target="_blank"
      >
        <img src={instaLogo} alt="instagram logo" loading="lazy" />
      </a>
      <a
        href="https://twitter.com/OxyaOrigin"
        className={classes.link}
        rel="noreferrer"
        target="_blank"
      >
        <img src={twitterLogo} alt="twitter logo" loading="lazy" />
      </a>
      <a
        href="https://discord.com/invite/oxyaorigin"
        className={classes.link}
        rel="noreferrer"
        target="_blank"
      >
        <img src={discordLogo} alt="discord logo" loading="lazy" />
      </a>
      <a
        href="https://www.linkedin.com/company/oxyaorigin/"
        className={classes.link}
        rel="noreferrer"
        target="_blank"
      >
        <img src={linkedinLogo} alt="linkedin logo" loading="lazy" />
      </a>
      <a
        href="https://www.youtube.com/channel/UCLv4c_vOHTDueESoMiIaDAg"
        className={classes.link}
        rel="noreferrer"
        target="_blank"
      >
        <img src={youtubeLogo} alt="youtube logo" loading="lazy" />
      </a>
    </div>
  );
}

SocialMedia.propTypes = {
  className: Proptypes.string,
};

SocialMedia.defaultProps = {
  className: '',
};
