import ethReward from '../assets/rewards/eth.gif';
import character from '../assets/rewards/character.gif';
import land from '../assets/rewards/land.gif';
import gunKryo from '../assets/rewards/gunKryo.gif';
import gunNaya from '../assets/rewards/gunNaya.gif';
import gunLuxion from '../assets/rewards/gunLuxion.gif';
import tokenOxya from '../assets/rewards/tokenOxya.gif';
import ressources2 from '../assets/rewards/2r.gif';
import ressources3 from '../assets/rewards/3r.gif';
import unidentified from '../assets/rewards/unidentified.gif';
import bigToken from '../assets/rewards/bigToken3.png';
import freeMintTurtle from '../assets/rewards/freeMintTurtle.jpg';
import stoxPro from '../assets/rewards/stox.png';
import nftParis from '../assets/rewards/nftParis.png';
import customShirt from '../assets/rewards/customShirt.png';
import thirdGate from '../assets/rewards/thirdGate.png';
import resurgence from '../assets/rewards/resurgence.png';
import imxToken from '../assets/rewards/imxToken.png';
import bulliezCrew from '../assets/rewards/bulliezCrew.png';
import chroToken from '../assets/rewards/chroToken.png';
import wyndblastNft from '../assets/rewards/wyndblastNft.png';
import customNftWearrMousepad from '../assets/rewards/customNftWearrMousePad.png';
import customNftWearrSticket from '../assets/rewards/customNftWearrSticket.png';
import tokenLukrya from '../assets/rewards/tokenLukrya.png';

import n3rd from '../assets/rewards/n3rd.gif';

import casino from '../assets/rewards/casino.png';
import tanashiGun from '../assets/rewards/tanashiGun.gif';
import palantirBeetle from '../assets/rewards/palantirBeetle.gif';
import cyberKicks from '../assets/rewards/cyberKicks.gif';
import cyberSpeakers from '../assets/rewards/cyberSpeakers.gif';
import cruxisHelm from '../assets/rewards/cruxisHelm.gif';
import alkarExtractor from '../assets/rewards/alkarExtractor.jpg';

import citizenConflictAvatar from '../assets/rewards/citizenConflictAvatar.png';
import electriumExtractor from '../assets/rewards/electriumExtractor.jpg';
import steelvarExtractor from '../assets/rewards/steelvarExtractor.jpg';
import citizenConflictAlphaKey from '../assets/rewards/citizenConflictAlphaKey.png';
import lightTowersGoldBulb from '../assets/rewards/golden_lamp.gif';
import lightTowersSilverBulb from '../assets/rewards/silver_lamp.gif';

import gatemapRewards from '../assets/rewards/gatemapRewards.jpg';

export const parseImageByTypeReward = (type) => {
  if (type === '0.3 ETH') return ethReward;
  if (type === 'Random Land') return land;
  if (type === 'OXYA LAND PLOT') return land;
  if (type === 'Random NFT Character') return character;
  if (type === 'Kryo Berg Splitter') return gunKryo;
  if (type === 'Naya Bone Defractor') return gunNaya;
  if (type === 'Lava Rifle') return gunLuxion;
  if (type === '1000 $OXYA') return bigToken;
  if (type === 'Emperor’s Vault') return bigToken;
  if (type === 'Raider’s Chest') return tokenOxya;
  if (type === 'Investor’s Pouch') return tokenOxya;
  if (type === 'Scavenger’s Scrapes') return tokenOxya;
  if (type === 'Mineral Reserve') return ressources2;
  if (type === "Crafter's Backpack") return ressources3;
  if (type === 'Building Kit') return ressources3;
  if (type === 'Unidentified Bio-mechanical Item') return unidentified;
  if (type === 'The Turtles - FREE MINT') return freeMintTurtle;
  if (type === 'Stox Pro') return stoxPro;
  if (type === 'Resurgence Cryotag') return resurgence;
  if (type === '7 $IMX Tokens') return imxToken;
  if (type === 'BulliezCrew NFT') return bulliezCrew;
  if (type === '3750 $CHRO Token') return chroToken;
  if (type === 'Wyndblast NFT') return wyndblastNft;
  if (type === 'N3RDGAMES NFT') return n3rd;
  if (type === '100 $LUKRYA') return tokenLukrya;
  if (type === 'Golden Ticket by Partouche Casinos') return casino;
  if (type === 'Cruxis Helm by CYBERCREW') return cruxisHelm;
  if (type === 'Cyber Speaker by CYBERCREW') return cyberSpeakers;
  if (type === 'Cyber Kicks by CYBERCREW') return cyberKicks;
  if (type === 'Palantir Beetle by CYBERCREW') return palantirBeetle;
  if (type === 'Tanashi Gun by CYBERCREW') return tanashiGun;
  if (type === 'Alkar Extractor') return alkarExtractor;
  if (type === 'Citizen Conflict Avatar') return citizenConflictAvatar;
  if (type === 'Electrium Extractor') return electriumExtractor;
  if (type === 'Steelvar Extractor') return steelvarExtractor;
  if (type === 'Citizen Conflict Alpha Key') return citizenConflictAlphaKey;
  if (type === 'Light Towers gold Bulb') return lightTowersGoldBulb;
  if (type === 'Light Towers silver Bulb') return lightTowersSilverBulb;
  if (type.indexOf('Custom NFT Wearr Mousepad') !== -1) return customNftWearrMousepad;
  if (type.indexOf('Custom NFT Wear Sticker') !== -1) return customNftWearrSticket;
  if (type.indexOf('NFT Paris Ticket') !== -1) return nftParis;
  if (type.indexOf('Custom T-shirt') !== -1) return customShirt;
  if (type.indexOf('Third Gate') !== -1) return thirdGate;
  return gatemapRewards;
};
