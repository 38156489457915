import React from 'react';
import cx from 'classnames';
import Proptypes from 'prop-types';

import withButtonPerso from './button-perso.style';

function ButtonPerso({
  classes, className, classNameText, text, onClick, firstRadius,
}) {
  return (
    <button className={cx(classes.root, className)} onClick={onClick} type="button" firstRadius={firstRadius}>
      <span className={cx(classes.text, classNameText)}>{text}</span>
    </button>
  );
}

ButtonPerso.propTypes = {
  classes: Proptypes.objectOf(
    Proptypes.string,
  ).isRequired,
  className: Proptypes.string,
  classNameText: Proptypes.string,
  text: Proptypes.string,
  onClick: Proptypes.func,
  firstRadius: Proptypes.bool,
};

ButtonPerso.defaultProps = {
  onClick: () => {},
  className: '',
  classNameText: '',
  text: 'Connect your Wallet',
  firstRadius: true,
};

export default withButtonPerso(ButtonPerso);
