export const getTotalOxya = (rewards) => {
  let result = 0;
  const rewardsOxyaType = rewards.filter((reward) => {
    const { type } = reward;
    if (type) { // Dans le cas où l'user reçoit des rewards et ne les a pas ouvert, reward.type === null
      if (type.indexOf('$OXYA') !== -1) return true;
    }
    return false;
  });
  const rewardsOxyaDescription = rewards.filter((reward) => {
    const { description, type } = reward;
    if (description && type) { // On ajoute l'item au tableau que si type != null et donc a ouvert la boxe
      if (description.indexOf('$OXYA') !== -1) return true;
    }
    return false;
  });
  rewardsOxyaType.forEach((rewardOxya) => {
    result += parseInt(rewardOxya.type.split(' ')[0], 10);
  });
  rewardsOxyaDescription.forEach((rewardOxya) => {
    result += parseInt(rewardOxya.description.split(' ')[0], 10);
  });
  return result;
};

export const getTotalLukrya = (rewards) => {
  let result = 0;
  const rewardsLukryaType = rewards.filter((reward) => {
    const { type } = reward;
    if (type) {
      if (type.indexOf('$LUKRYA') !== -1) return true;
    }
    return false;
  });

  rewardsLukryaType.forEach(() => {
    result += 100;
  });

  return result;
};
