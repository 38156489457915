import React from 'react';
import Proptypes from 'prop-types';

import { Link } from 'react-router-dom';
import withFooter from './footer.style';

function Footer({ classes }) {
  return (
    <footer className={classes.footer}>
      <div className={classes.title}>
        OXYA ORIGIN IS A REGISTERED TRADEMARK ALL RIGHTS RESERVED. OG
        <p style={{ marginTop: '1rem' }}>
          ALL LOGOS ARE REGISTERED TRADEMARKS OF THEIR RESPECTIVE OWNERS. ALL
          CONTENTS OF THIS DOCUMENT, UNLESS OTHERWISE CREDITED, ARE COPYRIGHT
          © 2023
        </p>
      </div>

      <div
        className={classes.wrapperContent}
      >
        <div>
          <strong>Ressources</strong>
          <a
            href="https://docsend.com/view/f8gnhc4kiw5dizhb"
            target="_blank"
            rel="noreferrer"
          >
            Whitepaper
          </a>
          <a
            href="https://oxyaorigin.typeform.com/betaaccess"
            target="_blank"
            rel="noreferrer"
          >
            Beta Wailitst

          </a>
        </div>
        <div>
          <strong>Company</strong>
          <Link to="/terms">Terms</Link>
          contact@oxyaorigin.com
        </div>
        <div>
          <strong>Social</strong>
          <a href="https://discord.com/invite/oxyaorigin" target="_blank" rel="noreferrer">Discord</a>
          <a href="https://twitter.com/OxyaOrigin" target="_blank" rel="noreferrer">Twitter</a>
          <a
            href="https://medium.com/@OxyaOriginOfficial"
            target="_blank"
            rel="noreferrer"
          >
            Medium

          </a>
          <a
            href="https://www.youtube.com/@oxyaorigin2813"
            target="_blank"
            rel="noreferrer"
          >
            Youtube

          </a>
          <a href="https://linktr.ee/Oxyaorigin" target="_blank" rel="noreferrer">All Links</a>
        </div>
      </div>
    </footer>
  );
}

Footer.propTypes = {
  classes: Proptypes.objectOf(
    Proptypes.string,
  ).isRequired,
};

export default withFooter(Footer);
