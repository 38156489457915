import React from 'react';
import cx from 'classnames';
import Proptypes from 'prop-types';

import { Typography } from '@material-ui/core';

import withCardInfo from './card-info.style';
import ButtonPerso from '../../_atoms/ButtonPerso/button-perso';

function CardInfo({
  classes,
  className,
  displayNumberClaim,
  number,
  text,
  cardClaim,
  rewards,
  textBtn,
  onClick,
  children,
  disableBtn,
  isLotteryCard,
  smallText,
}) {
  return (
    <div className={cx(classes.root, className)}>
      {
        cardClaim ? (
          <div className={classes.container} disableBtn={disableBtn} isLotteryCard={isLotteryCard} smallText={smallText}>
            <div className={classes.wrapperInfo}>
              {
                displayNumberClaim && (
                <div className={classes.wrapperIconNumber}>
                  <Typography variant="body1" className={cx(classes.number, classes.numberClaim)}>{rewards?.length > 0 ? '1' : '0'}</Typography>
                  {
                    isLotteryCard === false && (
                    <div className={classes.box} />
                    )
                  }
                </div>
                )
              }
              <Typography variant="body1" className={cx(classes.text, classes.textInfoClaim)}>{text}</Typography>
            </div>
            <ButtonPerso text={textBtn} className={classes.btn} classNameText={classes.btnText} onClick={onClick} />
          </div>
        ) : (
          // eslint-disable-next-line react/jsx-no-useless-fragment
          <>
            {
            children || (
              <>
                <Typography variant="body1" className={classes.number}>{number}</Typography>
                <Typography variant="body1" className={classes.text}>{text}</Typography>
              </>
            )
          }
          </>
        )
      }
    </div>
  );
}

CardInfo.propTypes = {
  classes: Proptypes.objectOf(
    Proptypes.string,
  ).isRequired,
  number: Proptypes.oneOfType([
    Proptypes.string, Proptypes.number,
  ]),
  text: Proptypes.oneOfType([Proptypes.string, Proptypes.node]),
  displayNumberClaim: Proptypes.bool,
  textBtn: Proptypes.string,
  onClick: Proptypes.func,
  className: Proptypes.string,
  cardClaim: Proptypes.bool,
  children: Proptypes.node,
  // eslint-disable-next-line react/forbid-prop-types
  rewards: Proptypes.array,
  disableBtn: Proptypes.bool,
  isLotteryCard: Proptypes.bool,
  smallText: Proptypes.bool,
};

CardInfo.defaultProps = {
  className: '',
  number: 0,
  displayNumberClaim: true,
  text: '',
  cardClaim: false,
  textBtn: 'Open',
  onClick: () => {},
  children: null,
  rewards: [],
  disableBtn: false,
  isLotteryCard: false,
  smallText: false,
};

export default withCardInfo(CardInfo);
