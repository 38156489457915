import axios from 'axios';
import { tokenConfig } from '../utils/configFetch';

export const getUser = async (cbSuccess = () => {}, cbError = () => {}) => {
  try {
    const res = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/users/me`, tokenConfig());
    cbSuccess(res.data);
  } catch (e) {
    console.log(e.response);
    cbError(e.response);
  }
};

export const setUser = async (discordId, cbSuccess = () => {}, cbError = () => {}) => {
  const body = JSON.stringify({
    discordId,
  });
  try {
    const res = await axios.patch(`${process.env.REACT_APP_API_ENDPOINT}/users`, body, tokenConfig());
    cbSuccess(res.data);
  } catch (e) {
    cbError(e.response);
  }
};
