import { withStyles } from '@material-ui/core';

export default withStyles(({ palette }) => ({
  root: {
    position: 'relative',
    overflow: 'hidden',
    padding: '1rem',
    display: 'flex',
    width: (props) => (props.noRewardBox ? '30rem' : '35rem'),
    height: (props) => (props.noRewardBox ? '18rem' : '35rem'),
    justifyContent: 'space-around',
    alignItems: 'center',
    flexDirection: 'column',
    border: `1px solid ${palette.primary.light}`,
    background: 'rgba(26, 64, 98, 0.3)',
    boxShadow: '0 14px 23px 4px rgba(0, 0, 0, 0.03)',
    borderTopRightRadius: (props) => props.firstRadius && '2rem',
    borderBottomLeftRadius: (props) => props.firstRadius && '2rem',
    borderTopLeftRadius: (props) => props.firstRadius === false && '2rem',
    borderBottomRightRadius: (props) => props.firstRadius === false && '2rem',
  },
  image: {
    width: (props) => (props.noRewardBox ? '30%' : '80%'),
    maxWidth: '20rem',
    borderRadius: '1rem',
  },
  text: {
    fontSize: (props) => (props.noRewardBox ? '1.6rem' : '1rem'),
    color: palette.common.white,
  },
  textBigger: {
    fontSize: (props) => (props.noRewardBox ? '2rem' : '1.4rem'),
    fontWeight: (props) => props.noRewardBox && 600,
    textTransform: (props) => props.noRewardBox && 'uppercase',
  },
}));
