import axios from 'axios';
import { tokenConfig } from '../utils/configFetch';

export const getRewardsAdmin = async (
  cbSuccess = () => {},
  cbError = () => {},
) => {
  try {
    const res = await axios.get(
      // `${process.env.REACT_APP_API_ENDPOINT}/admin/rewards/onlyEthCharas`,
      `${process.env.REACT_APP_API_ENDPOINT}/admin/rewards/onlyMegaBox`,
      tokenConfig(),
    );
    cbSuccess(res.data);
  } catch (e) {
    console.log(e.response);
    cbError(e.response);
  }
};

export const deliverRewardAdmin = async (
  id,
  cbSuccess = () => {},
  cbError = () => {},
) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_API_ENDPOINT}/admin/rewards/${id}/deliver`,
      {},
      tokenConfig(),
    );
    cbSuccess(res.data);
  } catch (e) {
    cbError(e.response);
  }
};

export const getLootsAdmin = async (
  cbSuccess = () => {},
  cbError = () => {},
) => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_API_ENDPOINT}/admin/rewards/staking/loots`,
      tokenConfig(),
    );
    cbSuccess(res.data);
  } catch (e) {
    cbError(e.response);
  }
};

/*
    loot object: {
        rewardType: String,
        quantity: Number,
        order: Number
    }
*/

export const createLootAdmin = async (
  loot,
  cbSuccess = () => {},
  cbError = () => {},
) => {
  const body = JSON.stringify({
    ...loot,
  });
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_API_ENDPOINT}/admin/rewards/staking/loots`,
      body,
      tokenConfig(),
    );
    cbSuccess(res.data);
  } catch (e) {
    cbError(e.response);
  }
};

/*
    loot object: {
        rewardType: String,
        quantity: Number,
        order: Number
    }
*/
export const updateLootAdmin = async (
  loot,
  cbSuccess = () => {},
  cbError = () => {},
) => {
  const body = JSON.stringify({
    ...loot,
  });
  try {
    const res = await axios.patch(
      `${process.env.REACT_APP_API_ENDPOINT}/admin/rewards/staking/loots/${loot.id}`,
      body,
      tokenConfig(),
    );
    cbSuccess(res.data);
  } catch (e) {
    cbError(e.response);
  }
};

export const deleteLootAdmin = async (
  lootId,
  cbSuccess = () => {},
  cbError = () => {},
) => {
  try {
    const res = await axios.delete(
      `${process.env.REACT_APP_API_ENDPOINT}/admin/rewards/staking/loots/${lootId}`,
      tokenConfig(),
    );
    cbSuccess(res.data);
  } catch (e) {
    cbError(e.response);
  }
};

export const getRewardsGatemapAdmin = async (
  cbSuccess = () => {},
  cbError = () => {},
) => {
  try {
    const res = await axios.get(
      // `${process.env.REACT_APP_API_ENDPOINT}/admin/rewards/onlyEthCharas`,
      `${process.env.REACT_APP_API_ENDPOINT}/admin/rewards/onlyGatemap`,
      tokenConfig(),
    );
    cbSuccess(res.data);
  } catch (e) {
    console.log(e.response);
    cbError(e.response);
  }
};

export const getLootsGatemapAdmin = async (
  cbSuccess = () => {},
  cbError = () => {},
) => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_API_ENDPOINT}/admin/rewards/gatemap/loots`,
      tokenConfig(),
    );
    cbSuccess(res.data);
  } catch (e) {
    cbError(e.response);
  }
};

/*
    loot object: {
        rewardType: String,
        quantity: Number,
        order: Number
    }
*/

export const createLootGatemapAdmin = async (
  loot,
  cbSuccess = () => {},
  cbError = () => {},
) => {
  const body = JSON.stringify({
    ...loot,
  });
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_API_ENDPOINT}/admin/rewards/gatemap/loots`,
      body,
      tokenConfig(),
    );
    cbSuccess(res.data);
  } catch (e) {
    cbError(e.response);
  }
};

/*
    lootGatemap object: {
        type: String,
        description: String,
        tokenIdLand: Number
        sizeLand: Number
    }
*/
export const updateLootGatemapAdmin = async (
  loot,
  cbSuccess = () => {},
  cbError = () => {},
) => {
  const body = JSON.stringify({
    ...loot,
  });
  try {
    const res = await axios.patch(
      `${process.env.REACT_APP_API_ENDPOINT}/admin/rewards/gatemap/loots/${loot.id}`,
      body,
      tokenConfig(),
    );
    cbSuccess(res.data);
  } catch (e) {
    cbError(e.response);
  }
};

export const deleteLootGatemapAdmin = async (
  lootId,
  cbSuccess = () => {},
  cbError = () => {},
) => {
  try {
    const res = await axios.delete(
      `${process.env.REACT_APP_API_ENDPOINT}/admin/rewards/gatemap/loots/${lootId}`,
      tokenConfig(),
    );
    cbSuccess(res.data);
  } catch (e) {
    cbError(e.response);
  }
};
