import { withStyles } from '@material-ui/core';

export default withStyles(({ breakpoints, palette }) => ({
  root: {
    border: 'solid 1px #27c6f7',
    position: 'relative',
    overflow: 'hidden',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    boxShadow: '0 0 30px #27c6f74d',
    background: 'rgba(26, 64, 98, 0.3)',
    borderRadius: '2rem',
  },
  header: {
    margin: '2rem',
    padding: '2rem',
    display: 'flex',
    height: '4rem',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  titleHeader: {
    color: palette.common.white,
    fontSize: '3rem',
    textTransform: 'uppercase',
  },
  textHeader: {
    color: palette.common.white,
    fontWeight: 'bold',
    fontSize: '1.2rem',
  },
  divider: {
    background: palette.primary.main,
    width: '100%',
    height: '2px',
    margin: '2rem 0',
  },
  body: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '2rem',
    columnGap: '2rem',
  },
  rewardResource: {
    height: '20rem',
  },
  wrapperCard: {
    display: 'flex',
    gap: '2rem',
    flexWrap: 'wrap',
    columnGap: '2rem',
    justifyContent: 'center',
    alignItems: 'center',
  },
  textNoFound: {
    color: 'white',
    fontSize: '1.6rem',
  },
  rewardType: {

  },
  textDiscord: {
    color: 'white',
    fontSize: '1.2rem',
  },
  textField: {
    width: '20rem',
    '& label.Mui-focused': {
      color: palette.primary.light,
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: palette.primary.light,
    },
    '& .MuiOutlinedInput-root': {
      '& input': {
        color: 'white',
      },
      '& fieldset': {
        borderColor: 'white',
        '& legend': {
          width: '13rem',
        },
      },
      '&:hover fieldset': {
        borderColor: palette.primary.light,
      },
      '&.Mui-focused fieldset': {
        borderColor: palette.primary.light,
      },
    },
  },
  btnSubmitDiscord: {
    color: palette.secondary.main,
    transition: '0.2s opacity ease-in-out',
    '&:hover': {
      opacity: 0.8,
    },
    marginRight: '1rem',
  },
  textBtnDiscord: {
    fontFamily: 'Montserrat, sans-serif',
    fontSize: '1.4rem',
  },
  footer: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'flex-end',
    gap: '2rem',
    flexWrap: 'wrap',
    [breakpoints.down(860)]: {
      justifyContent: 'center',
    },
  },
}));
