/* eslint-disable jsx-a11y/media-has-caption */
import React, {
  useState, useCallback, useEffect, useMemo,
} from 'react';
import {
  useAccount, useNetwork,
} from 'wagmi';

import { disconnect, signMessage } from '@wagmi/core';

import cx from 'classnames';
import Proptypes from 'prop-types';
import Web3 from 'web3';
import { InlineShareButtons } from 'sharethis-reactjs';

import { Typography } from '@material-ui/core';
import video from '../../../assets/openBox.mp4';

import loadingLogo from '../../../assets/loadingLogo.gif';

import SnackBarBottom from '../../_atoms/SnackBarBottom/snack-bar-bottom';

import Header from '../../_organisms/Header/header';
import CardInfo from '../../_molecules/CardInfo/card-info';

import ConnectedWallet from '../../../containers/ConnectedWallet/connected-wallet';
import ModalCenter from '../../_atoms/ModalCenter/modal-center';
import Inventory from '../../_organisms/Inventory/inventory';

import { loadState, parseBearerToken, removeState } from '../../../store/localStorage';
import { requestMessage, signIn } from '../../../api/auth';
import { claimRewards, userRewards } from '../../../api/rewards';
import { getUser, setUser } from '../../../api/user';
import { getNumberOxyaNftNoStakedOwner, getOxyaNftStakedOwner, numberOfStakeOxyans } from '../../../contracts/oxyaChara';
import {
  getLandStakedOwner, getNumberLandsNoStakedOwner, getSupplyLands, numberOfStakeLands,
} from '../../../contracts/oxyaLand';
import {
  getColonyStakedOwner, getNumberColoniesNoStakedOwner, getSupplyColonies, numberOfStakeColonies,
} from '../../../contracts/oxyaColony';

import withRewards from './rewards.style';
import { groupBy } from '../../../utils/array';
import CardReward from '../../_molecules/CardReward/card-reward';
import { parseImageByTypeReward } from '../../../utils/image';
import { getTotalLukrya, getTotalOxya } from '../../../utils/helpers';
import { titleFede } from '../../../utils/string';

import ambassadorImg from '../../../assets/fede/ambassador.png';
import chancellorImg from '../../../assets/fede/chancellor.png';
import councillorImg from '../../../assets/fede/councillor.png';
import governorImg from '../../../assets/fede/governor.png';
import landlordImg from '../../../assets/fede/landlord.png';

function Rewards({
  classes, location,
}) {
  const { chain } = useNetwork();

  const [newRewards, setNewRewards] = React.useState([]);
  const [supplyPlots, setSupplyPlots] = React.useState(0);
  const [supplyColonies, setSupplyColonies] = React.useState(0);
  const [numberStakedGlobalOxyans, setNumberStakedGlobalOxyans] = React.useState(null);
  const [numberStakedGlobalPlots, setNumberStakedGlobalPlots] = React.useState(null);
  const [numberStakedGlobalColonies, setNumberStakedGlobalColonies] = React.useState(null);

  const [numberStakedByOwner, setNumberStakedByOwner] = React.useState(null);
  const [numberNoStakedByOwner, setNumberNoStakedByOwner] = React.useState(null);
  const [fede, setFede] = React.useState('Loading...');

  const [quantityAlkar, setQuantityAlkar] = React.useState(0);
  const [quantitySteelvar, setQuantitySteelvar] = React.useState(0);
  const [quantityElectrium, setQuantityElectrium] = React.useState(0);

  const [inProcess, setInprocess] = React.useState(false);
  const [openBox, setOpenBox] = React.useState(false);
  const [canCloseOpenBox, setCanCloseOpenBox] = React.useState(false);
  const [activeTab, setActiveTab] = React.useState(1);
  const [discordName, setDiscordName] = React.useState('');
  const [rewards, setRewards] = useState([]);
  const [rewardsToDisplay, setRewardsToDisplay] = useState([]);
  const [currentAccount, setCurrentAccount] = useState(null);
  const [accessToken, setAccessToken] = useState(null);
  const [chainId, setChainId] = useState(parseInt(process.env.REACT_APP_CHAIN, 10));
  const [openSnackError, setOpenSnackError] = React.useState(false);
  const [openSnackSuccess, setOpenSnackSuccess] = React.useState(false);
  const [errorText, setErrorText] = React.useState('');
  const [successText, setSuccessText] = React.useState('');

  const oxyaQuantity = useMemo(() => {
    const result = getTotalOxya(rewards);
    return result;
  }, [rewards]);

  const lukryaQuantity = useMemo(() => {
    const result = getTotalLukrya(rewards);
    return result;
  }, [rewards]);

  const fedeImg = useMemo(() => {
    if (fede === 'Landlord') return landlordImg;
    if (fede === 'Ambassador') return ambassadorImg;
    if (fede === 'Governor') return governorImg;
    if (fede === 'Councillor') return councillorImg;
    if (fede === 'Chancellor') return chancellorImg;
    return null;
  }, [fede]);

  const getNumberNftByOwner = async () => {
    const result = {};
    const web3Infura = new Web3(new Web3.providers.HttpProvider(process.env.REACT_APP_ETH_NODE_URL));
    const oxyansStaked = await getOxyaNftStakedOwner(web3Infura.eth, currentAccount);
    const plotsStaked = await getLandStakedOwner(web3Infura.eth, currentAccount);
    const coloniesStaked = await getColonyStakedOwner(web3Infura.eth, currentAccount);

    const valueFede = titleFede(oxyansStaked.length, plotsStaked.length, coloniesStaked.length);
    setFede(valueFede);

    result.numberStaked = oxyansStaked.length + plotsStaked.length + coloniesStaked.length;
    const numberOxyansNoStaked = await getNumberOxyaNftNoStakedOwner(web3Infura.eth, currentAccount);
    const numberPlotsNoStaked = await getNumberLandsNoStakedOwner(web3Infura.eth, currentAccount);
    const numberColoniesNoStaked = await getNumberColoniesNoStakedOwner(web3Infura.eth, currentAccount);
    result.numberNoStaked = parseInt(numberOxyansNoStaked, 10) + parseInt(numberPlotsNoStaked, 10) + parseInt(numberColoniesNoStaked, 10);

    setNumberStakedByOwner(result.numberStaked);
    setNumberNoStakedByOwner(result.numberNoStaked);
  };

  const handleCloseSnack = () => {
    setOpenSnackError(false);
    setOpenSnackSuccess(false);
  };

  const handleChangeValueDiscordName = (e) => {
    setDiscordName(e.target.value);
  };

  const handleSubmitDiscord = () => {
    setUser(discordName, () => {
      setOpenSnackSuccess(true);
      setSuccessText('Your discord name has been updated');
    }, () => {
      setOpenSnackError(true);
      setErrorText('An error occured !');

      setTimeout(() => {
        setOpenSnackError(false);
        setErrorText('');
      }, [3000]);
    });
  };

  const logout = () => {
    setCurrentAccount(null);
    setAccessToken(null);
    removeState('accessToken');
    removeState('roles');
    removeState('address');
    removeState('walletconnect');
    disconnect();
  };

  const handleAuth = async (addressParam) => {
    const message = await requestMessage(addressParam);
    try {
      const signature = await signMessage({
        message,
      });
      await signIn(addressParam, signature, message, (elem) => {
        setAccessToken(parseBearerToken(elem.accessToken));
        getUserLocal();
        setInprocess(true);
        userRewards((resRewards) => {
          setInprocess(false);
          setRewards(resRewards);
          setRewardsToDisplay(resRewards.filter((elemReward) => elemReward.isDelivered === false));
        });
      });
    } catch (e) {
      logout();
    }
  };
  const { address } = useAccount({
    onDisconnect() {
      logout();
    },
  });

  useEffect(() => {
    const chainIdLocal = parseInt(chain?.id, 10);
    setChainId(chainIdLocal);
    const saveAddress = loadState('address', false);
    const accessTokenLocal = loadState('accessToken');
    setCurrentAccount(address);

    if (address !== saveAddress || accessTokenLocal === null || accessTokenLocal === undefined) {
      setAccessToken(null);
      if (chainIdLocal === parseInt(process.env.REACT_APP_CHAIN, 10)) {
        handleAuth(address);
      } else if (chain !== undefined) {
        setErrorText('Change your network to mainnet');
        setOpenSnackError(true);
        setTimeout(() => {
          setOpenSnackError(false);
          setErrorText('');
        }, [3000]);
        logout();
      }
    } else {
      setAccessToken(accessTokenLocal);
      setInprocess(true);
      getUserLocal();
      userRewards((resRewards) => {
        setInprocess(false);
        setRewards(resRewards);
        setRewardsToDisplay(resRewards.filter((elemReward) => elemReward.isDelivered === false));
      }, () => {
        logout();
      });
    }
  }, [address, chain]);

  const getUserLocal = async () => {
    await getUser((user) => {
      setDiscordName(user.discordId);
      user.resources.forEach((resource) => {
        if (resource.type === 'Alkar') setQuantityAlkar(resource.quantity);
        if (resource.type === 'Steelvar') setQuantitySteelvar(resource.quantity);
        if (resource.type === 'Electrium') setQuantityElectrium(resource.quantity);
      });
    });
  };

  const handleClaimRewards = () => {
    setOpenBox(true);
  };

  const getSupplies = useCallback(async () => {
    const web3Infura = new Web3(new Web3.providers.HttpProvider(process.env.REACT_APP_ETH_NODE_URL));
    numberOfStakeOxyans(web3Infura.eth, (res) => {
      setNumberStakedGlobalOxyans(res);
    });

    getSupplyLands(web3Infura.eth, (resSupply) => {
      setSupplyPlots(resSupply);
      numberOfStakeLands(web3Infura.eth, (res) => {
        setNumberStakedGlobalPlots(res);
      });
    });

    getSupplyColonies(web3Infura.eth, (resSupply) => {
      setSupplyColonies(resSupply);
      numberOfStakeColonies(web3Infura.eth, (res) => {
        setNumberStakedGlobalColonies(res);
      });
    });
  }, []);

  useEffect(() => {
    getSupplies();
  }, [getSupplies]);

  useEffect(() => {
    if (currentAccount) {
      getNumberNftByOwner();
    }
  }, [currentAccount]);

  const handleCloseModal = () => {
    if (canCloseOpenBox) setOpenBox(false);
  };

  const finishVideoRewards = () => {
    claimRewards((newRewardsRes) => {
      setNewRewards(newRewardsRes.filter((elem) => elem.type !== 'NONE'));
      userRewards((resRewards) => {
        setCanCloseOpenBox(true);
        setRewards(resRewards);
        setRewardsToDisplay(resRewards.filter((elemReward) => elemReward.isDelivered === false));
      });
    });
  };

  const handleClickToggleButtons = (newValue) => () => {
    setActiveTab(newValue);
    if (newValue === 0) {
      setRewardsToDisplay(rewards.filter((elem) => elem.isDelivered));
    } else {
      setRewardsToDisplay(rewards.filter((elem) => elem.isDelivered === false));
    }
  };

  return (
    <>
      <ModalCenter
        open={inProcess}
        classNameBody={classes.bodyModal}
        needTop={false}
        classNamePaper={classes.paperNoBackground}
        needCloseBtn={false}
      >
        <div className={classes.containerLoading}>
          <img src={loadingLogo} className={classes.loadingLogo} alt="loading" />
        </div>
      </ModalCenter>
      <ModalCenter
        className={cx(canCloseOpenBox && classes.classNameModalRewards)}
        open={openBox}
        classNameBody={classes.bodyModal}
        needTop={false}
        onClose={handleCloseModal}
        needCloseBtn={canCloseOpenBox}
        classNamePaper={cx(canCloseOpenBox === false && classes.paperNoBackground, canCloseOpenBox && classes.paperRewards)}
      >
        {
            canCloseOpenBox ? (
              <>
                {
                  newRewards?.length > 0 ? (
                    <>
                      <Typography className={classes.textCardReward}>Congratulations ! You won: </Typography>
                      <div className={classes.wrapperCardRewards}>
                        {
                          groupBy(newRewards, 'type').map((elem, index) => (
                            <div className={classes.containerCardOxya} key={elem?.groupList[0].id}>
                              <CardReward
                                numberReward={elem?.groupList.length}
                                name={elem?.groupList[0].type}
                                image={parseImageByTypeReward(elem?.groupList[0].type)}
                                firstRadius={index % 2 === 0}
                                description={elem?.groupList[0]?.description}
                              />
                            </div>
                          ))
                      }
                      </div>
                      <div className={classes.wrapperSocial}>
                        <InlineShareButtons
                          config={{
                            alignment: 'center', // alignment of buttons (left, center, right)
                            color: 'social', // set the color of buttons (social, white)
                            enabled: true, // show/hide buttons (true, false)
                            font_size: 16, // font size for the buttons
                            labels: 'cta', // button labels (cta, counts, null)
                            language: 'en', // which language to use (see LANGUAGES)
                            networks: [ // which networks to include (see SHARING NETWORKS)
                              'reddit',
                              'twitter',
                            ],
                            padding: 12, // padding within buttons (INTEGER)
                            radius: 4, // the corner radius on each button (INTEGER)
                            size: 40, // the size of each button (INTEGER)

                            // OPTIONAL PARAMETERS
                            url: 'oxyaorigin.com', // (defaults to current url)
                            image: parseImageByTypeReward(newRewards[0].type), // (defaults to og:image or twitter:image)
                            description: 'Thank you to @OxyaOrigin & all partners involved, looking forward to use it in the Shooter #game.', // (defaults to og:description or twitter:description)
                            title: `I Won the "${newRewards[0].type}" loot through staking ! Thanks to @OxyaOrigin & all partners involved !`, // (defaults to og:title or twitter:title)
                            message: 'custom email text', // (only for email sharing)
                            subject: 'Check out my #OXYA Megabox loot!', // (only for email sharing)
                            username: 'OxyaOrigin', // (only for twitter sharing)
                          }}
                        />
                      </div>
                    </>
                  ) : (
                    <Typography className={classes.textCardReward}>One in a million chance of an empty box, you should consider yourself lucky.</Typography>
                  )
                }
              </>
            ) : (
              <video muted autoPlay={openBox} className={classes.video} onEnded={finishVideoRewards}>
                <source src={video} type="video/mp4" />
              </video>
            )
          }
      </ModalCenter>
      <div className={cx(classes.root, inProcess && classes.noPointerEvent)}>
        {
        openSnackError && (
          <SnackBarBottom variant="error" open={openSnackError} onClose={handleCloseSnack} text={errorText} />
        )
        }
        {
          openSnackSuccess && (
            <SnackBarBottom variant="success" open={openSnackSuccess} onClose={handleCloseSnack} text={successText} />
          )
        }
        <ConnectedWallet
          account={currentAccount}
          chainId={chainId}
          accessToken={accessToken}
          fallback={(
            <div className={classes.bg}>
              <Header
                currentAccount={currentAccount}
                accessToken={accessToken}
                chainId={chainId}
                location={location}
              />
              <div className={classes.wrapperNoConnected}>
                <div className={classes.stakeOxyans}>
                  <Typography variant="h2" className={classes.title}>Staking Chapter 3</Typography>
                  {/* <ButtonPerso text="Connect your Wallet" width="300px" className={classes.btnConnect} onClick={handleClickConnect} /> */}
                </div>
                <div>
                  {
                    numberStakedGlobalOxyans !== null && (
                      <Typography variant="h3" className={classes.subTitle}>
                        Oxyans Staked
                        {' '}
                        {`${numberStakedGlobalOxyans} / 7898`}
                      </Typography>
                    )
                  }
                  {
                    numberStakedGlobalPlots !== null && (
                      <Typography variant="h3" className={classes.subTitle}>
                        Plots Staked
                        {' '}
                        {`${numberStakedGlobalPlots} / ${supplyPlots}`}
                      </Typography>
                    )
                  }
                  {
                    numberStakedGlobalColonies !== null && (
                      <Typography variant="h3" className={classes.subTitle}>
                        Colonies Staked
                        {' '}
                        {`${numberStakedGlobalColonies} / ${supplyColonies}`}
                      </Typography>
                    )
                  }
                </div>
              </div>
            </div>
          )}
        >
          {
            rewards ? (
              <>
                <Header
                  currentAccount={currentAccount}
                  accessToken={accessToken}
                  chainId={chainId}
                  location={location}
                />
                <div className={classes.wrapperInfos}>
                  <CardInfo number={numberStakedByOwner !== null ? `${numberStakedByOwner}/${numberNoStakedByOwner + numberStakedByOwner}` : 'Loading...'} text="Total assets Staked" />
                  <CardInfo>
                    {
                      fedeImg && (
                      <img alt="ambassador" src={fedeImg} style={{ width: '6rem', marginBottom: '5px' }} />
                      )
                    }
                    <Typography
                      variant="body1"
                      style={{
                        color: 'white', whiteSpace: 'pre-wrap', fontWeight: 600, fontSize: '1.4rem',
                      }}
                    >
                      {fede}
                    </Typography>
                  </CardInfo>
                  <CardInfo onClick={handleClaimRewards} rewards={rewards.filter((elem) => elem.isClaimed === false)} cardClaim firstRadius text="Megabox ready" />
                </div>
                <Inventory
                  listRewards={rewards}
                  listRewardsToDisplay={rewardsToDisplay}
                  className={classes.listNfts}
                  idRoot="inventory"
                  onClickToggleButtons={handleClickToggleButtons}
                  activeTab={activeTab}
                  valueDiscordName={discordName}
                  onChangeDiscordName={(handleChangeValueDiscordName)}
                  onClickSubmitDiscord={handleSubmitDiscord}
                  quantityOxya={oxyaQuantity}
                  quantityLukrya={lukryaQuantity}
                  quantityAlkar={quantityAlkar}
                  quantitySteelvar={quantitySteelvar}
                  quantityElectrium={quantityElectrium}
                />
              </>
            ) : (
              <div className={classes.containerLoading}>
                <img src={loadingLogo} className={classes.loadingLogo} alt="loading" />
              </div>
            )
            }
        </ConnectedWallet>
      </div>
    </>
  );
}

Rewards.propTypes = {
  classes: Proptypes.objectOf(
    Proptypes.string,
  ).isRequired,
  location: Proptypes.shape({
    hash: Proptypes.string,
    key: Proptypes.string,
    search: Proptypes.string,
    // eslint-disable-next-line react/forbid-prop-types
    state: Proptypes.object,
  }),
};

Rewards.defaultProps = {
  location: {},
};

export default withRewards(Rewards);
