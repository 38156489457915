/* eslint-disable import/prefer-default-export */
export const listDrawerHeader = [
  {
    key: 'home',
    to: '/#',
    onClick: () => {},
    text: 'Rewards',
  },
  {
    key: 'oxyans',
    to: '/oxyans',
    onClick: () => {},
    text: 'Oxyans',
  },
  {
    key: 'plots',
    to: '/plots',
    onClick: () => {},
    text: 'Plots',
  },
  {
    key: 'colonies',
    to: '/colonies',
    onClick: () => {},
    text: 'Colonies',
  },
  /* {
    key: 'mergeLands',
    to: '/merge-lands',
    onClick: () => {},
    text: 'Merge Lands',
  }, */
];
