// eslint-disable-next-line import/no-cycle
import { contractOxyaNft, contractStakingOxyan } from './contract';

export const stake = async (eth, account, tokensId, cbSuccess = () => {}, cbError = () => {}) => {
  const stakingContract = new eth.Contract(contractStakingOxyan.abi, contractStakingOxyan.address);
  const oxyaNftContract = new eth.Contract(contractOxyaNft.abi, contractOxyaNft.address);

  const isApprovedForAll = await oxyaNftContract.methods.isApprovedForAll(account, contractStakingOxyan.address).call();
  if (isApprovedForAll === false) {
    oxyaNftContract.methods.setApprovalForAll(contractStakingOxyan.address, true).send({ from: account })
      .then(async () => {
        stakingContract.methods.batchStake(tokensId).send({ from: account })
          .then((elem) => {
            cbSuccess(elem);
          })
          .catch((error) => {
            cbError(error);
          });
      })
      .catch((error) => { cbError(error); });
  } else {
    await stakingContract.methods.batchStake(tokensId).send({ from: account })
      .then((elem) => {
        cbSuccess(elem);
      })
      .catch((error) => {
        cbError(error);
      });
  }
};

export const unstake = async (eth, account, tokensId, cbSuccess = () => {}, cbError = () => {}) => {
  const stakingContract = new eth.Contract(contractStakingOxyan.abi, contractStakingOxyan.address);
  const oxyaNftContract = new eth.Contract(contractOxyaNft.abi, contractOxyaNft.address);

  const isApprovedForAll = await oxyaNftContract.methods.isApprovedForAll(account, contractStakingOxyan.address).call();
  if (isApprovedForAll === false) {
    oxyaNftContract.methods.setApprovalForAll(contractStakingOxyan.address, true).send({ from: account })
      .then(async () => {
        stakingContract.methods.batchUnstake(tokensId).send({ from: account })
          .then((elem) => {
            cbSuccess(elem);
          })
          .catch((err) => {
            cbError(err);
          });
      })
      .catch((err) => { cbError(err); });
  } else {
    await stakingContract.methods.batchUnstake(tokensId).send({ from: account })
      .then((elem) => {
        cbSuccess(elem);
      })
      .catch((err) => {
        cbError(err);
      });
  }
};
