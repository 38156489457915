import axios from 'axios';
import { tokenConfig } from '../utils/configFetch';

export const getLandByTokenIdAndSize = async (tokenId, size, cbSuccess = () => { }, cbError = () => { }) => {
  try {
    const res = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/lands/${tokenId}/${size}`, tokenConfig());
    cbSuccess(res.data);
    return res.data;
  } catch (e) {
    cbError(e.response);
  }
  return null;
};
