export const loadState = (attribute = 'accessToken', bearer = true) => {
  try {
    let serializedState;
    if (bearer) {
      serializedState = `Bearer ${localStorage.getItem(attribute)}`.replace('"', '');
      serializedState = `"${serializedState}`;
    } else {
      serializedState = localStorage.getItem(attribute);
    }

    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    return undefined;
  }
};

export const parseBearerToken = (token) => {
  const serializedState = `Bearer ${token}`.replace('"', '');
  return serializedState;
};

export const saveState = (attribute, state) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem(attribute, serializedState);
  } catch (err) {
    // ignore
  }
};

export const removeState = (attribute) => {
  localStorage.removeItem(attribute);
};
