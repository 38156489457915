/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/self-closing-comp */
import React from 'react';
import cx from 'classnames';
import Proptypes from 'prop-types';

import {
  Box, Checkbox, Divider, Typography,
} from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import withListNfts from './listNfts.style';
import ButtonPerso from '../../_atoms/ButtonPerso/button-perso';
import CardOxya from '../../_molecules/CardOxya/card-oxya';

function ListNfts({
  classes,
  className,
  classNameHeader,
  checkedStakeAll,
  onChangeCheckedStake,
  onChangeCheckedStakeAll,
  onClickApply,
  textBtnApply,
  textHeader,
  textNoFound,
  listNft,
  listCardNoFound,
  listStakeChecked,
  history,
  displayCheckedAll,
  onClickInfo,
  typeNft,
  displayMergeBtn,
  onClickMergeBtn,
}) {
  const handleClickBtnNoFound = (elem) => () => {
    if (elem.oxyaRooter) {
      history.push({
        pathname: elem.to,
      });
    } else {
      window.location = elem.to;
    }
  };

  const handleChangeCheckedStake = (index) => () => {
    onChangeCheckedStake(index);
  };

  return (
    <div className={cx(classes.root, className)}>
      <div className={cx(classes.header, classNameHeader)}>
        <div className={classes.leftHeader}>
          <Typography variant="h1" className={classes.titleHeader}>{textHeader}</Typography>
          <InfoIcon color="secondary" className={classes.icon} fontSize="large" onClick={onClickInfo} />
        </div>
        {
                displayMergeBtn && (
                  <ButtonPerso
                    text="Merge"
                    onClick={onClickMergeBtn}
                    className={classes.btnApply}
                  />
                )
              }
        {
          listNft?.length > 0 && (
            <div className={classes.wrapperStakeAll}>
              {
                displayCheckedAll && (
                  <>
                    <Box display="flex" alignItems="center">
                      <Typography variant="body1" className={classes.textHeader}>Select all: </Typography>
                      <Checkbox
                        checked={checkedStakeAll}
                        onChange={onChangeCheckedStakeAll}
                        className={classes.checkbox}
                        icon={<span className={classes.iconCheckbox} />}
                        checkedIcon={<span className={cx(classes.iconCheckbox, classes.checkedIconCheckbox)} />}
                      />
                    </Box>
                  </>
                )
              }
              <ButtonPerso
                text={textBtnApply}
                onClick={onClickApply}
                className={classes.btnApply}
              />
            </div>
          )
        }
      </div>
      <Divider className={classes.divider} />
      <div className={classes.body}>
        {
              listNft?.length === 0 ? (
                <>
                  <Typography variant="body1" className={classes.textNoFound}>{textNoFound}</Typography>
                  <div className={classes.wrapperCard}>
                    {
                        listCardNoFound.map((elem, index) => (
                          <CardOxya className={classes.cardInfo} key={elem.key} firstRadius={index % 2 === 0}>
                            <Typography className={classes.textCardNoFound}>{elem.textCardNoFound}</Typography>
                            <ButtonPerso
                              className={classes.btn}
                              onClick={handleClickBtnNoFound(elem)}
                              classNameText={classes.textBtn}
                              text={elem.textBtn}
                            />
                          </CardOxya>
                        ))
                    }
                  </div>
                </>
              ) : (
                <div className={classes.wrapperCard}>
                  {
                    listNft.map((elem, index) => (
                      <div className={classes.containerCardOxya} key={elem.tokenId}>
                        <CardOxya
                          className={classes.cardInfo}
                          background={elem.image}
                          firstRadius={false}
                          checked={listStakeChecked[index]?.checked || false}
                          onChangeChecked={handleChangeCheckedStake(index)}
                        />
                        <Typography className={classes.tokenId}>{`${typeNft} #${elem.tokenId}`}</Typography>
                      </div>
                    ))
                  }
                </div>
              )
          }
      </div>
      <div style={{ height: '2rem' }}></div>
    </div>
  );
}

ListNfts.propTypes = {
  classes: Proptypes.objectOf(
    Proptypes.string,
  ).isRequired,
  className: Proptypes.string,
  textHeader: Proptypes.string,
  textNoFound: Proptypes.string,
  textBtnApply: Proptypes.string,
  listNft: Proptypes.array,
  listCardNoFound: Proptypes.array,
  history: Proptypes.objectOf(
    Proptypes.oneOfType([
      Proptypes.number,
      Proptypes.func,
      Proptypes.object,
      Proptypes.string,
    ]),
  ),
  onChangeCheckedStakeAll: Proptypes.func,
  onChangeCheckedStake: Proptypes.func,
  onClickApply: Proptypes.func,
  checkedStakeAll: Proptypes.bool,
  listStakeChecked: Proptypes.array.isRequired,
  onClickInfo: Proptypes.func,
  displayCheckedAll: Proptypes.bool,
  typeNft: Proptypes.string,
  displayMergeBtn: Proptypes.bool,
  onClickMergeBtn: Proptypes.func,
  classNameHeader: Proptypes.string,
};

ListNfts.defaultProps = {
  className: '',
  classNameHeader: '',
  textHeader: 'My Oxyans',
  textNoFound: 'No Oxyans detected.',
  textBtnApply: 'Apply',
  listNft: [],
  displayCheckedAll: true,
  listCardNoFound: [],
  history: null,
  checkedStakeAll: false,
  onClickApply: () => {},
  onChangeCheckedStakeAll: () => {},
  onChangeCheckedStake: () => {},
  onClickInfo: () => {},
  typeNft: 'Oxyan',
  displayMergeBtn: false,
  onClickMergeBtn: () => {},
};

export default withListNfts(ListNfts);
