/* eslint-disable jsx-a11y/media-has-caption */
import React, {
  useState, useCallback, useEffect,
} from 'react';
import {
  useAccount, useNetwork,
} from 'wagmi';

import { disconnect, signMessage } from '@wagmi/core';

import cx from 'classnames';
import Proptypes from 'prop-types';
import Web3 from 'web3';

import { Box, Divider, Typography } from '@material-ui/core';

import loadingLogo from '../../../assets/loadingLogo.gif';

import Header from '../../_organisms/Header/header';
import CardInfo from '../../_molecules/CardInfo/card-info';

import { textInfoRewardsLands, textCardNoFoundLands } from '../../../constants/text';
import ConnectedWallet from '../../../containers/ConnectedWallet/connected-wallet';
import ModalCenter from '../../_atoms/ModalCenter/modal-center';

import { loadState, parseBearerToken, removeState } from '../../../store/localStorage';
import { requestMessage, signIn } from '../../../api/auth';
import { numberOfStakeOxyans } from '../../../contracts/oxyaChara';
import {
  getLandNft, numberOfStakeLands, getSupplyLands,
} from '../../../contracts/oxyaLand';
import { stake, unstake } from '../../../contracts/stakingLand';

import withPlots from './plots.style';
import ListNfts from '../../_organisms/ListNfts/listNfts';
import { getSupplyColonies, numberOfStakeColonies } from '../../../contracts/oxyaColony';

function Plots({
  classes, history, location,
}) {
  const { chain } = useNetwork();
  const [numberToStake, setNumberToStake] = useState(0);
  const [supplyColonies, setSupplyColonies] = React.useState(0);
  const [supplyPlots, setSupplyPlots] = React.useState(0);
  const [numberToUnStake, setNumberToUnStake] = React.useState(0);
  const [numberStakedGlobalOxyans, setNumberStakedGlobalOxyans] = React.useState(null);
  const [numberStakedGlobalPlots, setNumberStakedGlobalPlots] = React.useState(null);
  const [numberStakedGlobalColonies, setNumberStakedGlobalColonies] = React.useState(null);
  const [inProcess, setInprocess] = React.useState(false);
  const [plotsNftLocal, setPlotsNftLocal] = React.useState(null);
  const [openModalInfoRewards, setOpenModalInfoRewards] = useState(false);
  const [openModalStaking, setOpenModalStaking] = useState(false);
  const [currentAccount, setCurrentAccount] = useState(null);
  const [accessToken, setAccessToken] = useState(null);
  const [chainId, setChainId] = useState(parseInt(process.env.REACT_APP_CHAIN, 10));
  const [checkedStake, setCheckedStake] = useState([]);
  const [checkedStakeAll, setCheckedStakeAll] = useState(false);

  const logout = () => {
    setCurrentAccount(null);
    setAccessToken(null);
    removeState('accessToken');
    removeState('roles');
    removeState('address');
    disconnect();
  };

  const handleAuth = async (addressParam) => {
    const message = await requestMessage(addressParam);
    try {
      const signature = await signMessage({
        message,
      });
      await signIn(addressParam, signature, message, (elem) => {
        const web3Infura = new Web3(new Web3.providers.HttpProvider(process.env.REACT_APP_ETH_NODE_URL));
        setAccessToken(parseBearerToken(elem.accessToken));
        getPlotNftLocal(web3Infura.eth, addressParam);
      });
    } catch (e) {
      logout();
    }
  };
  const { address } = useAccount({
    onDisconnect() {
      logout();
    },
  });

  const getPlotNftLocal = async (eth, account, changeStakeCheck = true) => {
    const tokens = await getLandNft(eth, account);
    if (changeStakeCheck) {
      const stakeCheckedLocal = tokens.map((token) => ({
        checked: token.isStake,
      }));
      setCheckedStake(stakeCheckedLocal);
    }
    setPlotsNftLocal(tokens);
  };

  useEffect(() => {
    const chainIdLocal = parseInt(chain?.id, 10);
    setChainId(chainIdLocal);
    const saveAddress = loadState('address', false);
    const accessTokenLocal = loadState('accessToken');
    setCurrentAccount(address);
    if (address !== saveAddress || accessTokenLocal === null || accessTokenLocal === undefined) {
      setAccessToken(null);
      if (chainIdLocal === parseInt(process.env.REACT_APP_CHAIN, 10)) {
        handleAuth(address);
      }
    } else {
      const web3Infura = new Web3(new Web3.providers.HttpProvider(process.env.REACT_APP_ETH_NODE_URL));
      setAccessToken(accessTokenLocal);
      getPlotNftLocal(web3Infura.eth, address);
    }
  }, [address, chain]);

  /* const drawsLottery = useMemo(() => {
    const nbDrawLottery = nbDrawPublicLottery + nbDrawWlLottery;
    const result = Array.from({ length: nbDrawLottery }, (_, i) => i + 1);
    return result;
  }, [nbDrawPublicLottery, nbDrawWlLottery]);

  const textLottery = useMemo(() => {
    if (lotteryActive === false) return 'Not Available';
    return 'Number of Ticket(s)\nIf you won, your wallet will receive 3 ETH.';
  }, [lotteryActive]); */

  const getSupplies = useCallback(async () => {
    const web3Infura = new Web3(new Web3.providers.HttpProvider(process.env.REACT_APP_ETH_NODE_URL));
    numberOfStakeOxyans(web3Infura.eth, (res) => {
      setNumberStakedGlobalOxyans(res);
    });

    getSupplyLands(web3Infura.eth, (resSupply) => {
      setSupplyPlots(resSupply);
      numberOfStakeLands(web3Infura.eth, (res) => {
        setNumberStakedGlobalPlots(res);
      });
    });

    getSupplyColonies(web3Infura.eth, (resSupply) => {
      setSupplyColonies(resSupply);
      numberOfStakeColonies(web3Infura.eth, (res) => {
        setNumberStakedGlobalColonies(res);
      });
    });
  }, []);

  useEffect(() => {
    getSupplies();
  }, [getSupplies]);

  const handleCheckedStakeAll = (event) => {
    setCheckedStakeAll(event.target.checked);
    let checkedStakeLocal;
    let numberToStakeTmp = 0;
    let numberToUnStakeTmp = 0;
    if (event.target.checked) {
      checkedStakeLocal = [...checkedStake].map(() => ({
        checked: true,
      }));
    } else {
      checkedStakeLocal = [...checkedStake].map(() => ({
        checked: false,
      }));
    }

    for (let i = 0; i < plotsNftLocal.length; i += 1) {
      if (plotsNftLocal[i].isStake === false && checkedStakeLocal[i].checked === true) {
        numberToStakeTmp += 1;
      }
      if (plotsNftLocal[i].isStake === true && checkedStakeLocal[i].checked === false) {
        numberToUnStakeTmp += 1;
      }
    }

    setNumberToStake(numberToStakeTmp);
    setNumberToUnStake(numberToUnStakeTmp);
    setCheckedStake(checkedStakeLocal);
  };

  const handleChangeCheckedStake = (index) => {
    const checkedStakeLocal = [...checkedStake];
    const checkedElem = checkedStakeLocal[index].checked;
    if (checkedElem === true) {
      checkedStakeLocal[index].checked = false;
      if (plotsNftLocal[index].isStake === false) {
        setNumberToStake(numberToStake - 1);
      }
      if (plotsNftLocal[index].isStake === true) {
        setNumberToUnStake(numberToUnStake + 1);
      }
    } else {
      checkedStakeLocal[index].checked = true;
      if (plotsNftLocal[index].isStake === false) {
        setNumberToStake(numberToStake + 1);
      }
      if (plotsNftLocal[index].isStake === true) {
        setNumberToUnStake(numberToUnStake - 1);
      }
    }
    setCheckedStake(checkedStakeLocal);
  };

  const handleOpenModalStaking = () => {
    setOpenModalStaking(true);
  };

  const handleCloseModal = () => {
    setOpenModalInfoRewards(false);
    setOpenModalStaking(false);
  };

  /* const handleClaimLottery = () => {
    const { ethereum } = window;
    const web3 = new Web3(ethereum);
    const web3Infura = new Web3(new Web3.providers.HttpProvider(process.env.REACT_APP_ETH_NODE_URL));

    setInprocess(true);
    lottery(web3.eth, currentAccount, async () => {
      setNbDrawWlLottery(0);
      setNbDrawPublicLottery(0);
      getPlotNftLocal(web3Infura.eth, currentAccount);
      setInprocess(false);
    }, () => {
      setInprocess(false);
    });
  }; */

  const handleClickConfirm = () => {
    const { ethereum } = window;
    const web3 = new Web3(ethereum);
    const web3Infura = new Web3(new Web3.providers.HttpProvider(process.env.REACT_APP_ETH_NODE_URL));
    let tokenIdsStaked = [];
    let tokenIdsUnStaked = [];
    for (let i = 0; i < plotsNftLocal.length; i += 1) {
      if (plotsNftLocal[i].isStake === false && checkedStake[i].checked) {
        tokenIdsStaked.push(parseInt(plotsNftLocal[i].tokenId, 10));
      }
      if (plotsNftLocal[i].isStake && checkedStake[i].checked === false) {
        tokenIdsUnStaked.push(parseInt(plotsNftLocal[i].tokenId, 10));
      }
    }

    handleCloseModal();

    if (tokenIdsStaked.length > 0) {
      setInprocess(true);
      stake(web3.eth, currentAccount, tokenIdsStaked, async () => {
        await getPlotNftLocal(web3Infura.eth, currentAccount);
        setInprocess(false);
        tokenIdsStaked = [];
        setNumberToStake(0);
      }, () => {
        setInprocess(false);
      });
    }

    if (tokenIdsUnStaked.length > 0) {
      setInprocess(true);
      unstake(web3.eth, currentAccount, tokenIdsUnStaked, async () => {
        getPlotNftLocal(web3Infura.eth, currentAccount);
        setInprocess(false);
        tokenIdsUnStaked = [];
        setNumberToUnStake(0);
      }, () => {
        setInprocess(false);
      });
    }
  };

  const handleOpenModalInfoRewards = () => {
    setOpenModalInfoRewards(true);
  };

  return (
    <>
      <ModalCenter
        open={inProcess}
        classNameBody={classes.bodyModal}
        needTop={false}
        classNamePaper={classes.paperNoBackground}
        needCloseBtn={false}
      >
        <div className={classes.containerLoading}>
          <img src={loadingLogo} className={classes.loadingLogo} alt="loading" />
        </div>
      </ModalCenter>
      <ModalCenter
        open={openModalInfoRewards}
        classNameBody={cx(classes.bodyModal, classes.bodyModalInfoRewards)}
        needTop={false}
        classNamePaper={{ width: '80%' }}
        onClose={handleCloseModal}
      >
        <Typography className={classes.titleInfoRewards}>
          {textInfoRewardsLands.title}
        </Typography>
        <Typography className={classes.descriptionInfoRewards}>
          {textInfoRewardsLands.description}
        </Typography>
      </ModalCenter>
      <ModalCenter
        open={openModalStaking}
        onClose={handleCloseModal}
        button
        classNameBody={cx(classes.bodyModal, classes.bodyModalStaked)}
        onClickConfirm={handleClickConfirm}
      >
        <div className={classes.staked}>
          <Typography className={classes.titleBodyModal}>
            {`${numberToStake} Plots`}
          </Typography>
          <Typography className={classes.textBodyModal}>
            are going to be staked
          </Typography>
        </div>
        <Divider orientation="vertical" className={classes.dividerModal} />
        <div className={classes.unStaked}>
          <Typography className={classes.titleBodyModal}>
            {`${numberToUnStake} Plots`}
          </Typography>
          <Typography className={classes.textBodyModal}>
            are going to be unstaked
          </Typography>
        </div>
      </ModalCenter>
      <div className={cx(classes.root, inProcess && classes.noPointerEvent)}>
        <ConnectedWallet
          account={currentAccount}
          chainId={chainId}
          accessToken={accessToken}
          fallback={(
            <div className={classes.bg}>
              <Header
                currentAccount={currentAccount}
                accessToken={accessToken}
                chainId={chainId}
                location={location}
              />
              <div className={classes.wrapperNoConnected}>
                <div />
                <div className={classes.stakeOxyans}>
                  <Typography variant="h2" className={classes.title}>Staking Chapter 3</Typography>
                  {/* <ButtonPerso text="Connect your Wallet" width="300px" className={classes.btnConnect} onClick={handleClickConnect} /> */}
                </div>
                <div>
                  {
                    numberStakedGlobalOxyans !== null && (
                      <Typography variant="h3" className={classes.subTitle}>
                        Oxyans Staked
                        {' '}
                        {`${numberStakedGlobalOxyans} / 7898`}
                      </Typography>
                    )
                  }
                  {
                    numberStakedGlobalPlots !== null && (
                      <Typography variant="h3" className={classes.subTitle}>
                        Plots Staked
                        {' '}
                        {`${numberStakedGlobalPlots} / ${supplyPlots}`}
                      </Typography>
                    )
                    }
                  {
                    numberStakedGlobalColonies !== null && (
                      <Typography variant="h3" className={classes.subTitle}>
                        Colonies Staked
                        {' '}
                        {`${numberStakedGlobalColonies} / ${supplyColonies}`}
                      </Typography>
                    )
                  }
                </div>
              </div>
            </div>
          )}
        >
          {
            plotsNftLocal ? (
              <>
                <Header
                  currentAccount={currentAccount}
                  accessToken={accessToken}
                  chainId={chainId}
                  location={location}
                />
                <div className={classes.wrapperInfos}>
                  <CardInfo number={plotsNftLocal.length} text="Total Plots Owned" />
                  <CardInfo number={`${plotsNftLocal.filter((elem) => elem.isStake).length}/${plotsNftLocal.length}`} text="Number of Plots staked" />
                  <CardInfo cardClaim isLotteryCard firstRadius text="Explore the Gatemap" textBtn="Soon" rewards={[]} displayNumberClaim={false} />
                </div>
                <ListNfts
                  listCardNoFound={textCardNoFoundLands}
                  listNft={plotsNftLocal}
                  className={classes.listNfts}
                  history={history}
                  checkedStakeAll={checkedStakeAll}
                  onChangeCheckedStakeAll={handleCheckedStakeAll}
                  listStakeChecked={checkedStake}
                  onChangeCheckedStake={handleChangeCheckedStake}
                  onClickApply={(handleOpenModalStaking)}
                  onClickInfo={handleOpenModalInfoRewards}
                  textHeader="My Plots"
                  textNoFound="No Plots"
                  typeNft="Plot"
                  classNameHeader={classes.headerListNfts}
                />
                <Box />
              </>
            ) : (
              <div className={classes.containerLoading}>
                <img src={loadingLogo} className={classes.loadingLogo} alt="loading" />
              </div>
            )
            }
        </ConnectedWallet>
      </div>
    </>
  );
}

Plots.propTypes = {
  classes: Proptypes.objectOf(
    Proptypes.string,
  ).isRequired,
  history: Proptypes.objectOf(
    Proptypes.oneOfType([
      Proptypes.number,
      Proptypes.func,
      Proptypes.object,
      Proptypes.string,
    ]),
  ),
  location: Proptypes.shape({
    hash: Proptypes.string,
    key: Proptypes.string,
    search: Proptypes.string,
    // eslint-disable-next-line react/forbid-prop-types
    state: Proptypes.object,
  }),
};

Plots.defaultProps = {
  history: {},
  location: {},
};

export default withPlots(Plots);
