export const COLUMN_REWARDS = [
  {
    title: 'Id', field: 'id', type: 'numeric', editable: 'never', filtering: false,
  },
  {
    title: 'Type Loot', field: 'type', editable: 'never',
  },
  {
    title: 'Description Loot', field: 'description', editable: 'never',
  },
  {
    title: 'IsClaimed',
    field: 'isClaimed',
    lookup: {
      false: 'Not Claimed',
      true: 'Claimed',
    },
    editable: 'never',
  },
  {
    title: 'Lost',
    field: 'lost',
    lookup: {
      false: 'Not Lost',
      true: 'Lost',
    },
    editable: 'never',
  },
  {
    title: 'IsDelivered',
    field: 'isDelivered',
    lookup: {
      false: 'Not Delivered',
      true: 'Delivered',
    },
  },
  {
    title: 'Date of Reward',
    field: 'dateOfReward',
    type: 'date',
    editable: 'never',
  },
  {
    title: 'Wallet Address',
    field: 'walletAddress',
    editable: 'never',
  },
  {
    title: 'Email',
    field: 'email',
    editable: 'never',
  },
  {
    title: 'Discord id',
    field: 'discordId',
    editable: 'never',
  },
  {
    title: 'Name',
    field: 'name',
    editable: 'never',
  },
  {
    title: 'Username',
    field: 'userName',
    editable: 'never',
  },
  {
    title: 'Date of Claim',
    field: 'dateOfClaim',
    type: 'date',
    editable: 'never',
  },
  {
    title: 'Date of Delivery',
    field: 'dateOfClaim',
    type: 'date',
    editable: 'never',
  },
];

export const COLUMN_LOOT = [
  {
    title: 'Id', field: 'id', type: 'numeric', editable: 'never', filtering: false,
  },
  {
    title: 'Order', field: 'order', type: 'numeric',
  },
  {
    title: 'Reward Type', field: 'rewardType',
  },
  {
    title: 'Description Loot', field: 'description',
  },
  {
    title: 'Quantity', field: 'quantity', type: 'numeric',
  },
];

export const COLUMN_LOOT_GATEMAP = [
  {
    title: 'Id', field: 'id', type: 'numeric', editable: 'never', filtering: false,
  },
  {
    title: 'Type', field: 'type',
  },
  {
    title: 'Description Loot', field: 'description',
  },
  {
    title: 'TokenId Land', field: 'tokenIdLand', type: 'numeric',
  },
  {
    title: 'Size Land', field: 'sizeLand', type: 'numeric',
  },
];
