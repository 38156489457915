import { loadState } from '../store/localStorage';

// Setup config/headers and token
export const tokenConfig = () => {
  // Get token from localstorage
  const token = loadState('accessToken');

  // Headers
  const config = {
    headers: {
      'Content-type': 'application/json',
    },
  };

  // If token, add to headers
  if (token) {
    config.headers.authorization = token;
  }

  return config;
};

export const configNoToken = () => {
  const config = {
    headers: {
      'Content-type': 'application/json',
    },
  };

  return config;
};
