import { withStyles } from '@material-ui/core';

export default withStyles(({ palette }) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'initial',
    overflow: 'scroll',
  },
  paper: {
    // maxWidth: '50rem',
    display: 'flex',
    flexDirection: 'column',
    height: 'auto',
    margin: '0 2rem',
    background: '#030a10',
    boxShadow: '0px 73px 99px #07101A',
    border: `1px solid ${palette.primary.light}`,
    padding: '2rem',
    '&:focus': {
      outline: 'none',
    },
  },
  closeButton: {
    position: 'relative',
    left: '50%',
    bottom: '1.4rem',
    color: palette.grey[500],
  },
  top: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  titleTop: {
    color: 'white',
    fontSize: '1.6rem',
    textAlign: 'center',
  },
  textTop: {
    color: 'white',
    fontSize: '1.2rem',
    textAlign: 'center',
  },
  body: {
    margin: '2rem 0',
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '2rem',
    gap: '2rem',
  },
  btnText: {
    color: 'white',
    textTransform: 'initial',
  },
  btnCancel: {
    background: 'linear-gradient(90deg, #9B9B9B 0%, #3C4446 100%)',
    borderColor: '#5A5A5A',
    width: '12rem',
  },
  btnConfirm: {
    background: palette.gradient.btnBlue,
    borderColor: palette.primary.light,
    width: '12rem',
  },
}));
