/* eslint-disable consistent-return */
import axios from 'axios';
import { saveState } from '../store/localStorage';
import { configNoToken } from '../utils/configFetch';

export const sign = async (signer, cbSuccess = () => {}, cbError = () => {}) => {
  const address = await signer.getAddress();
  const body = JSON.stringify({
    address,
  });
  try {
    const res = await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/auth/signin`, body, configNoToken());
    const { message } = res.data;
    const signature = await signer.signMessage(`${message}`);

    const body2 = JSON.stringify({
      signature,
      address,
      message,
    });
    const res2 = await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/auth/signin/sign`, body2, configNoToken());
    const { accessToken } = res2.data;
    const { roles } = res2.data;
    saveState('accessToken', accessToken);
    saveState('roles', roles);
    cbSuccess({ accessToken, roles });
  } catch (e) {
    cbError(e);
  }
};

export const requestMessage = async (address, cbSuccess = () => {}, cbError = () => {}) => {
  const body = JSON.stringify({
    address,
  });
  try {
    const res = await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/auth/signin`, body, configNoToken());
    const { message } = res.data;
    cbSuccess(message);
    return message;
  } catch (e) {
    cbError(e);
  }
};

export const signIn = async (address, signature, message, cbSuccess = () => {}, cbError = () => {}) => {
  const body = JSON.stringify({
    address,
    signature,
    message,
  });
  try {
    const res = await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/auth/signin/sign`, body, configNoToken());
    const { accessToken, roles } = res.data;
    saveState('accessToken', accessToken);
    saveState('roles', roles);
    saveState('address', address);
    cbSuccess({ accessToken, roles });
    return { accessToken, roles };
  } catch (e) {
    cbError(e);
  }
};

export const getNonce = async (cbSuccess = () => {}, cbError = () => {}) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/authSiwe/nonce`, configNoToken());
    cbSuccess(response.data);
    return response.data;
  } catch (e) {
    cbError(e);
  }
};

export const verify = async (message, signature, cbSuccess = () => {}, cbError = () => {}) => {
  try {
    const body = JSON.stringify({
      message,
      signature,
    });
    const verifyRes = await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/authSiwe/verify`, body, configNoToken);
    cbSuccess(verifyRes.data);
    return verifyRes.data;
  } catch (e) {
    cbError(e);
  }
};

export const logout = async (cbSuccess = () => {}, cbError = () => {}) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/authSiwe/nonce`, configNoToken());
    cbSuccess(response.data);
    return response.data;
  } catch (e) {
    cbError(e);
  }
};
