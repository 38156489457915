import axios from 'axios';
import { tokenConfig } from '../utils/configFetch';

export const userRewards = async (cbSuccess = () => {}, cbError = () => {}) => {
  try {
    const res = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/rewards/`, tokenConfig());
    cbSuccess(res.data);
  } catch (e) {
    cbError(e.response);
  }
};

export const claimRewards = async (cbSuccess = () => {}, cbError = () => {}) => {
  try {
    const res = await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/rewards/claim`, {}, tokenConfig());
    cbSuccess(res.data);
  } catch (e) {
    cbError(e.response);
  }
};
