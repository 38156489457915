import { withStyles } from '@material-ui/core';

export default withStyles(({ palette }) => ({
  root: {
    display: 'flex',
    height: (props) => props.height || '4rem',
    justifyContent: 'center',
    alignItems: 'center',
    fontFamily: 'Montserrat, sans-serif',
    color: palette.common.white,
    fontSize: '1.2rem',
    textAlign: 'center',
    textDecoration: 'none',
    textTransform: 'uppercase',
    cursor: 'pointer',
    background: 'transparent linear-gradient(132deg, #FFCD62C9 0%, #FFCD62C9 0%, #736629C9 100%) 0% 0% no-repeat padding-box',
    border: '1px solid #E5BA57',
    borderBottomLeftRadius: (props) => props.firstRadius && '1.5rem',
    borderTopRightRadius: (props) => props.firstRadius && '1.5rem',

    borderBottomRightRadius: (props) => props.firstRadius === false && '1.5rem',
    borderTopLeftRadius: (props) => props.firstRadius === false && '1.5rem',
    transition: '0.2s opacity ease-in-out',
    '&:hover': {
      opacity: '0.8',
    },
  },
  text: {
    fontSize: '1.4rem',
    textTransform: 'uppercase',
  },
}));
