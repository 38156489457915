import { withStyles } from '@material-ui/core';

export default withStyles(({ palette }) => ({
  root: {
    position: 'relative',
    border: (props) => (props.cardClaim ? '1px solid #FFC300' : 'solid 1px #27c6f7'),
    overflow: 'hidden',
    display: 'flex',
    minWidth: '30%',
    width: '300px',
    height: '120px',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    boxShadow: (props) => ((props.rewards?.length === 0 || props.disableBtn) && props.cardClaim
      ? 'inset 1px 0px 32px #626262CE, 4px 10px 10px #071326B2'
      : '0 0 30px #27c6f74d'),
    background: (props) => {
      if (props.cardClaim) {
        if (props.rewards.length === 0) {
          return 'linear-gradient(357deg, #69696973 0%, #FFFFFF73 100%)';
        }
        return `transparent linear-gradient(357deg, ${palette.primary.main} 0%, ${palette.primary.light} 100%) 0% 0% no-repeat padding-box !important`;
      }
      return 'rgba(26, 64, 98, 0.3)';
    },
    borderRadius: '2rem',
    backgroundRepeat: 'no-repeat',
  },
  number: {
    alignSelf: 'center',
    color: 'white',
    fontSize: '3.5rem',
    fontWeight: 800,
  },
  text: {
    color: 'white',
    fontSize: (props) => (props.smallText ? '1rem' : '1.4rem'),
    whiteSpace: 'pre-wrap',
    fontWeight: 400,
  },
  numberClaim: {
    color: (props) => ((props.rewards?.length > 0 && props.disableBtn === false) ? 'white' : '#222222'),
    fontSize: (props) => ((props.rewards?.length > 0 && props.disableBtn === false) ? '4rem' : '3rem'),
  },
  textInfoClaim: {
    textAlign: 'center',
    fontWeight: 600,
    fontSize: '2rem',
    marginLeft: (props) => props.isLotteryCard === false && '5rem',
    color: (props) => ((props.rewards?.length > 0 && props.disableBtn === false) ? 'white' : '#222222'),
  },
  wrapperIconNumber: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row-reverse',
    justifyContent: 'left',
  },
  wrapperInfo: {
    flex: 3,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    padding: '0 20px',
    justifyContent: 'space-between',
  },
  logoNumber: {
    display: 'flex',
    alignItems: 'center',
  },
  box: {
    width: '4rem',
    height: '4rem',
    marginRight: '1rem',
  },
  btn: {
    background: (props) => (props.rewards?.length === 0 || props.disableBtn) && '#434343',
    borderColor: (props) => (props.rewards?.length === 0 || props.disableBtn) && '#626262',
    pointerEvents: (props) => (props.rewards?.length === 0 || props.disableBtn) && 'none',
    cursor: (props) => (props.rewards?.length === 0 || props.disableBtn) && 'initial',
    width: '15rem',
    minWidth: '9rem',
    flex: 1,
  },
  btnText: {
    color: (props) => (props.rewards?.length === 0 || props.disableBtn) && '#222222',
    textTransform: 'initial',
    fontWeight: 600,
  },
}));
